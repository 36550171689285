.App {
  text-align: center;
}
#root{
  overflow-x: hidden;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pre_loader {
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  background-color: var(--bs-gray-light);
  /* background: linear-gradient(-45deg, #f99d1c, #ffc20e, #ffdd00, #f99d1c); */
  background-size: 400% 400%;
  /* animation: gradient .6s ease infinite; */
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

/* ==================================================== */
/*     |!|!|!|!|  CSS ADDED BY PUROHIT A  |!|!|!|!|     */
/* ==================================================== */
/*==============================================*/
/*          !!! GOOGLE FONT IMPORT !!!          */
/*==============================================*/
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:wght@400;700&display=swap');

/* --------------------------
  !!  SITE  ( " font-family: 'PT Serif', serif; " ) FONT-WEIGHT !!
  +--------------+--------------+
  | FONT-WEIGHT  | Value        |
  +--------------+--------------+
  | thin         | 100          |
  | ex-light     | 200          |
  | light        | 300          |
  | regular      | 400          |
  | medium       | 500          |
  | semi-bold    | 600          |
  | bold         | 700          |
  | ex-bold      | 800          |
  | black        | 900          |
  +--------------+--------------+
  --------------------------- */
/* ============================================== */
/*              !! GENERATED FONT !!              */
/* ============================================== */
@font-face {
  font-family: 'HelveticaNeue Thin';
  src: url('./fonts/HelveticaNeue-Thin.eot');
  src: url('./fonts/HelveticaNeue-Thin.eot?#iefix') format('embedded-opentype'),
    url('./fonts/HelveticaNeue-Thin.woff2') format('woff2'),
    url('./fonts/HelveticaNeue-Thin.woff') format('woff'),
    url('./fonts/HelveticaNeue-Thin.ttf') format('truetype'),
    url('./fonts/HelveticaNeue-Thin.svg#HelveticaNeue-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url('./fonts/HelveticaNeue.eot');
  src: url('./fonts/HelveticaNeue.eot?#iefix') format('embedded-opentype'),
    url('./fonts/HelveticaNeue.woff2') format('woff2'),
    url('./fonts/HelveticaNeue.woff') format('woff'),
    url('./fonts/HelveticaNeue.ttf') format('truetype'),
    url('./fonts/HelveticaNeue.svg#HelveticaNeue') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue Medium';
  src: url('./fonts/HelveticaNeue-Medium.eot');
  src: url('./fonts/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'),
    url('./fonts/HelveticaNeue-Medium.woff2') format('woff2'),
    url('./fonts/HelveticaNeue-Medium.woff') format('woff'),
    url('./fonts/HelveticaNeue-Medium.ttf') format('truetype'),
    url('./fonts/HelveticaNeue-Medium.svg#HelveticaNeue-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* ============================================= */
/* !!!!        SITE GLOBAL STYLE CSS        !!!! */
/* ============================================= */
:root {
  --bs-gray: #666666;
  --bs-danger: #d90000;

  --bs-gray-200: #f4f4f4;
  --bs-gray-300: #e0e0e0;
  --bs-gray-500: #A7A7A7;

  --bs-gray-rgb: 102, 102, 102;
  --bs-danger-rgb: 217, 0, 0;

  --bs-primary: #004E6D;
  --bs-secondary: #FEC010;
  --bs-secondary-dark: #F5A623;

  --bs-light: #f0f4f6;
  --bs-dark: #01293A;
  --bs-primary-rgb: 0, 78, 109;
  --bs-secondary-rgb: 254, 192, 16;
  --bs-secondary-dark-rgb: 245, 166, 35;

  --bs-light-rgb: 240, 244, 246;
  --bs-dark-rgb: 1, 41, 58;
  --bs-body-color-rgb: var(--bs-primary-rgb);
  --bs-font-sans-serif: "basic-sans", sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-body-font-size: 18px;
  --bs-body-font-weight: 300;
  --bs-body-line-height: 1.44;
  --bs-body-color: var(--bs-primary);
  --bs-base-transition: all 0.3s ease-in-out;
  --bs-transparent: transparent;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

a {
  color: inherit;
}

a:hover {
  color: var(--bs-primary);
}

a,
.btn,
button {
  cursor: pointer;
}

:focus,
:active,
.active {
  outline: 0 none;
}

a,
a:hover,
a:focus,
a:active,
button,
button:hover,
button:focus,
input,
input:hover,
input:focus,
select,
select:hover,
select:focus,
textarea,
textarea:hover,
textarea:focus,
.btn,
.btn:hover,
.btn:focus,
.btn:active:focus,
.btn:active.focus,
.btn.active:focus,
.btn.active.focus {
  text-decoration: none;
  -webkit-text-decoration-skip: objects;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-control:focus,
[type="text"]:focus,
[type="email"]:focus,
[type="checkbox"]:focus,
[type="password"]:focus,
[type="file"]:focus,
[type="radio"]:focus,
[type="submit"]:focus,
[type="date"]:focus,
[type="time"]:focus,
[type="tel"]:focus,
[type="search"]:focus,
[type="button"]:focus,
[type="number"]:focus,
[type='reset']:focus,
textarea:focus,
select:focus {
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

button::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='button']::-moz-focus-inner {
  border: none;
  padding: 0;
}

.shadow {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.12) !important;
}

.border-light {
  border-color: var(--bs-gray-200) !important;
}

.border-exlight {
  border-color: var(--bs-gray-100) !important;
}

.main {
  min-height: 70vh;
}

.can_we_help_button {
  cursor: pointer;
  width: 176px;
  height: 46px;
  border-radius: 4px 4px 0 0;
  /*background: #84be9c;
  border: 1px solid #59966e;
   box-shadow: inset 2px 2px 0 rgba(90, 150, 101, .50);*/
  background: #004e6d;
  border: 1px solid #004e6d;
  box-shadow: inset 2px 2px 0 rgb(90 103 150 / 50%);
  transform: rotate(-90deg);
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: #fff;
  padding: 11px;
  position: fixed;
  right: -64px;
  bottom: 155px;

}

.can_we_help_button img {
  width: 20px;
  transform: rotate(90deg);
}

/* ============================================= */
/* !!!!         SITE TYPOGRAPHY CSS         !!!! */
/* ============================================= */
p:empty {
  display: none;
}

.small,
small {
  font-size: 14px;
  line-height: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400;
  margin: 0 0 10px 0;
}

.list-group,
.card>.list-group,
.card>.list-group:last-child {
  border-radius: 0;
  border: 0;
}

.list-group .list-group-item,
.list-group li,
.list-group-item {
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  background-color: var(--bs-transparent);
}

.order_data_listing_block .list-group-item {
  font-size: 15px;
}

h1,
.h1 {
  font-size: 46px;
  line-height: 52px;
}

h2,
.h2 {
  font-size: 34px;
  line-height: 40px;
}

h3,
.h3 {
  font-size: 26px;
  line-height: 27px;
}

h4,
.h4 {
  font-size: 24px;
  line-height: 32px;
}

h5,
.h5 {
  font-size: 22px;
  line-height: 26px;
}

h6,
.h6 {
  font-size: 18px;
  line-height: 20px;
}

.display-1,
.display-2 {
  font-weight: 700;
  letter-spacing: -0.03em;
}

.display-1 {
  font-size: 39px;
  line-height: 36px;
}

.display-2 {
  font-size: 29px;
  line-height: 26px;
}

.display-4 {
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: var(--bs-secondary);
}

.display-5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--bs-dark);
}

.display-6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--bs-gray-dark);
}

.label_tag {
  display: inline-block;
  border-radius: 4px;
  background-color: var(--bs-primary-light);
  padding: 4px 22px;
  margin: 0;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--bs-primary);
}

/* ============================================= */
/* !!!!       All MINIFY CSS                !!!! */
/* ============================================= */
a:hover,
a:focus {
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

/* ============================================= */
/* !!!!        COMMON BUTTON STYLE          !!!! */
/* ============================================= */
.btn {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.22;
  color: var(--bs-dark);
  border: 2px solid transparent;
  padding: 7px 9px 5px;
  border-radius: 3px;
  text-transform: uppercase;
  min-width: 150px;
}

.btn-primary {
  color: var(--bs-white);
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  --bs-shadow-opacity: 1;
  box-shadow: 2px 2px rgba(var(--bs-dark-rgb), var(--bs-shadow-opacity));
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:disabled {
  color: var(--bs-white);
  background: var(--bs-primary);
  border-color: var(--bs-primary);
  box-shadow: 5px 5px rgba(var(--bs-dark-rgb), var(--bs-shadow-opacity));
}

.btn-outline-primary {
  border-width: 1px;
  color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary:disabled {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.btn-secondary {
  color: var(--bs-dark);
  background-color: var(--bs-secondary);
  border-color: var(--bs-secondary);
  --bs-shadow-opacity: 1;
  box-shadow: 2px 2px rgba(var(--bs-secondary-dark-rgb), var(--bs-shadow-opacity));
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary:disabled {
  color: var(--bs-dark);
  background: var(--bs-secondary);
  border-color: var(--bs-secondary);
  box-shadow: 5px 5px rgba(var(--bs-secondary-dark-rgb), var(--bs-shadow-opacity));
}

.btn-secondary-outline {
  border-color: var(--bs-secondary);
}

.btn-secondary-outline:hover,
.btn-secondary-outline:focus,
.btn-secondary-outline:active,
.btn-secondary-outline:disabled {
  background-color: var(--bs-secondary);
  color: var(--bs-white);
}

/*
  @media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
      .btn {
        padding: 13px 35px 8.5px;
      }
      .navbar_right .btn.btn-primary {
        padding: 12px 16px 10px;
      }
      .btn-lg {
        padding: 16px 35px 13px;
      }
    }
  }
  */

/* BUTTON LINK STYLE */
.btn-link {
  font-size: 14px;
  color: var(--bs-dark);
  text-decoration: none;
  text-transform: uppercase;
}

.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  color: var(--bs-secondary);
}

.btn-link svg {
  margin-left: 11px;
}

/* BUTTON LARGE STYLE */
.btn-lg {
  font-size: 18px;
  line-height: 1.22;
}

/* ============================================= */
/* !!!!             FORM STYLE              !!!! */
/* ============================================= */
form .form-group {
  margin: 0 0 18px;
}

.form-text.text-muted {
  font-weight: normal;
  font-style: italic;
  font-size: 12px;
  line-height: 14px;
  color: var(--bs-gray-600) !important;
  display: block;
  margin: 20px 0 0;
}

.form-control,
.PhoneInputInput {
  font-size: 16px;
  line-height: 22px;
  border: 1px solid var(--bs-light);
  box-shadow: inset -4px -4px var(--bs-light);
  border-radius: 3px;
  background-color: var(--bs-white);
  font-weight: 300;
  color: var(--bs-gray-500);
  padding: 12px 20px;
  margin: 0;
}

.form-control:not(:placeholder-shown),
.PhoneInputInput:not(:placeholder-shown) {
  color: var(--bs-gray-500);
}

.form-control.form-error,
.form-check-input.form-error,
.form-error {
  border: 1px solid var(--bs-danger) !important;
}

.form-error.PhoneInput input {
  border-color: var(--bs-danger) !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: var(--bs-white);
}

label.form-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: var(--bs-dark);
  margin: 0 0 14px;
}

textarea.form-control {
  min-height: 124px;
  resize: none;
}

input[type="search"].form-control::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.PhoneInputCountry {
  border: 1px solid var(--bs-gray-500);
  border-radius: 4px;
  padding: 0 10px;
}

/* !!! Form Checkbox And Radio !!! */
.form-check {
  min-height: unset;
  margin: 0;
  padding: 0;
}

.form-check-inline {
  margin-right: 16px;
  margin-bottom: 11px;
}

.form-check .form-check-input,
.form-check .form-check-input[type="checkbox"] {
  background-color: var(--bs-white);
  width: 20px;
  height: 20px;
  border: 1px solid var(--bs-primary);
  border-radius: 0;
  margin: 0 10px 0 0;
  cursor: pointer;
  box-shadow: inset -3px -3px 0px rgba(var(--bs-primary-rgb), 0.45);
  -webkit-box-shadow: inset -3px -3px 0px rgba(var(--bs-primary-rgb), 0.45);
  background-repeat: no-repeat;
  background-position: center;
}

.form-check .form-check-input[type=radio] {
  border-radius: 100%;
}

.form-check .form-check-label {
  color: var(--bs-primary);
  cursor: pointer;
}

.checkfont .form-check .form-check-label {
  font-size: 15px;
}

.form-check .form-check-input:checked[type=radio],
.form-check .form-check-input[type=radio][checked] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='6' fill='%23004e6d'/%3E%3C/svg%3E");
  background-color: var(--bs-white);
  background-size: 12px;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.form-check .form-check-input:checked[type=checkbox],
.form-check .form-check-input[type=checkbox][checked] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.222' height='12.222' viewBox='0 0 12.222 12.222'%3E%3Crect width='12.222' height='12.222' fill='%23004e6d'/%3E%3C/svg%3E%0A");
  background-color: var(--bs-white);
  border-color: var(--bs-primary);
  background-size: 12px;
  box-shadow: none;
  -webkit-box-shadow: none;
}

/* Form Select */
.form-control.form-select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.705' height='6.913' viewBox='0 0 11.705 6.913'%3E%3Cg transform='translate(-879.439 -307.439)'%3E%3Cline x2='4.792' y2='4.792' transform='translate(880.5 308.5)' fill='none' stroke='%2326425a' strokeLinecap='round' stroke-width='1.5'/%3E%3Cline x1='4.792' y2='4.792' transform='translate(885.292 308.5)' fill='none' stroke='%2326425a' strokeLinecap='round' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 94% 50%;
  padding: 15px 45px 15px 12px;
}

/* progress*/
.progress {
  background-color: var(--bs-gray-200);
  margin: 16px 0 0;
}

.progress-bar {
  font-size: 12px;
}

/* !!! PhoneInputCountry !!! */
.PhoneInput .PhoneInputCountry {
  border: 1px solid var(--bs-light);
  box-shadow: inset -4px -4px var(--bs-light);
}

/* ============================================= */
/* !!!!       FORM INPUT PLACEHOLDER        !!!! */
/* ============================================= */
.form-control::-webkit-input-placeholder {
  color: var(--bs-gray-500);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form-control::-moz-placeholder {
  color: var(--bs-gray-500);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form-control:-ms-input-placeholder {
  color: var(--bs-gray-500);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form-control::-ms-input-placeholder {
  color: var(--bs-gray-500);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form-control:-moz-placeholder {
  color: var(--bs-gray-500);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* !! field focus !! */
.form-control:focus::-webkit-input-placeholder {
  color: var(--bs-transparent);
}

.form-control:focus::-moz-placeholder {
  color: var(--bs-transparent);
}

.form-control:focus:-ms-input-placeholder {
  color: var(--bs-transparent);
}

.form-control:focus::-ms-input-placeholder {
  color: var(--bs-transparent);
}

.form-control:focus:-moz-placeholder {
  color: var(--bs-transparent);
}

/* ============================================= */
/* !!!!         MODAL POPUP  STYLE          !!!! */
/* ============================================= */
body.modal-open #root {
  filter: blur(3px);
}

.modal .h4 {
  font-size: 24px;
  margin: 0 0 45px;
}

.modal .h4 p {
  font-size: 14px;
  margin: 24px 0 0;
}

.modal .modal-header .btn-close {
  color: var(--bs-gray);
  font-size: 10px;
  margin: 0 0 0 auto;
  padding: 4px;
}

.modal .modal-dialog:not(.modal-dialog-centered) {
  margin-top: 0;
}

@media (min-width: 992px) {
  .modal .modal-lg {
    max-width: 730px;
  }
}

.modal .modal-content {
  box-shadow: 0 0 7px rgba(0, 0, 0, .12);
  border: 0;
  border-radius: 0;
}

.modal .modal-body {
  padding: 32px 60px 69px;
}

.modal .btn-link {
  font-size: 12px;
}

.modal .form-check .form-check-input {
  border-radius: 2px;
  border: 1px solid var(--bs-gray-500);
}

.modal .form-check .form-check-label,
.form-check-label-small {
  font-size: 12px;
  color: var(--bs-gray);
}

/* !!! Cart Modal  !!!*/
.cart_modal .modal-dialog {
  margin: 0;
  margin-right: 0;
  margin-left: auto;
  height: 100vh;
  max-width: 600px;
}

.cart_modal .modal-content {
  height: 100%;
}

.cart_products_listing li .product_cate {
  position: absolute;
  left: 0;
  top: 15px;
  width: 65px;
  height: 65px;
  padding: 0;
}

.cart_products_listing li .product_cate h6 {
  line-height: 15px;
  font-size: 14px;
}

.cart_products_listing li .product_cate small {
  font-size: 12px;
  line-height: 15px;
}

.cart_products_listing li,
.cart_products_listing li.list-group-item {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--bs-gray-dark);
  border: 0;
  padding: 15px 30px 15px 0;
  margin: 0;
  border-bottom: 1px solid var(--bs-gray-100);
}

.cart_products_listing li .cart_price {
  position: relative;
  right: auto;
  top: auto;
}

.cart_products_listing li .btn-link {
  position: absolute;
  right: 0;
  top: 22px;
  min-width: inherit;
}

.cart_products_listing li .link-item h6 {
  margin: 0 0 10px;
}

.subtotal span {
  font-weight: 500;
  color: var(--bs-secondary);
}

/* !!! Share Modal  !!!*/
.share_modal .modal-body .btn {
  margin: 54px 0 0;
}

.share_modal .modal-body .social_media_list li {
  width: 54px;
  height: 54px;
  background-color: var(--bs-secondary);
  border: 2px solid var(--bs-secondary);
  border-radius: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
  margin: 0 8px;
}

.share_modal .modal-body .social_media_list li:hover {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.share_modal .modal-body .social_media_list li:hover svg path {
  fill: var(--bs-white);
}

/* !!! Login Modal !!! */
.login_modal .btn+.btn {
  margin: 20px 0 0;
}

/* !!! Create Account modal !!! */
.create_account_modal .btn {
  min-width: 344px;
}

/* !!! Need Help modal !!! */
.modal h2 {
  margin: 0 0 35px;
}

.need_help_modal .form-check .form-check-label {}

.need_help_modal .form-check {
  margin: 27px 0;
}

.need_help_modal .form-check .form-check-input[type="checkbox"] {
  width: 14px;
  height: 14px;
}

.need_help_modal .btn-primary {
  min-width: 171px;
}

/* !!! Update Your Order  modal !!! */
.update_order_modal .information_block {
  margin: 34px 0;
}

.update_order_modal .form-check {
  margin: 27px 0;
}

.update_order_modal .btn-primary {
  min-width: 171px;
}

.update_order_data .table {
  margin: 0 0 30px;
}

.update_order_data table tr * {
  font-weight: 500;
  color: var(--bs-dark);
  padding: 0 0 20px;
  border: 0;
}

.update_order_data table tr th {
  min-width: 155px;
  height: 26px;
}

.oneChoice.form-check .form-check-input {
  flex-grow: 1;
  flex-shrink: 0;
  width: 20px;
  max-width: 20px;
}

/* ============================================= */
/* !!!!            TOPBAR STYLE             !!!! */
/* ============================================= */
nav.navbar {
  z-index: 1054;
}

.navbar .navbar_right a.nav-link {
  font-size: 14px;
  padding: 0;
  margin-right: 15px;
  text-transform: uppercase;
}

.navbar .navbar_right a.nav-link.cart_link {
  margin-right: 0;
  margin-left: 10px;
}

/*
  .navbar .navbar_right a.nav-link:last-child {
      margin-right: 0;
  }
  */
.navbar .navbar_right a.nav-link svg {
  margin-right: 8.5px;
}

.navbar .navbar_right .form-select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.817' height='8.408' viewBox='0 0 12.817 8.408'%3E%3Cpath d='M0,0,5,6l5-6' transform='translate(1.408 1.408)' fill='none' stroke='rgba(1,41,58,0.45)' strokeLinecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E%3C/svg%3E%0A");
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  color: var(--bs-dark);
  border-radius: 3px;
  border: 1px solid var(--bs-gray-200);
  padding: 4px 28px 2px 15px;
  box-shadow: inset -2px -2px 0 0 var(--bs-gray-300);
  background-size: 10px 6px;
  margin-right: 20px;
}

.navbar .navbar_right .btn {
  min-width: 109px;
  font-size: 13px;
  line-height: normal;
  padding: 4px 0 2px;
  margin: 0 6px;
}

.navbar .navbar_right .account_link {
  margin-left: 10px;
}

.navbar .navbar_right a.nav-link.account_link svg {
  margin: 0;
}

.cart_link {
  position: relative;
}

.cart_link .badge {
  position: absolute;
  width: 13px;
  height: 13px;
  line-height: 16px;
  font-size: 11px;
  font-weight: bold;
  top: -4px;
  left: 17px;
  border-radius: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart_link svg {
  flex-shrink: 0;
}

.cart_link .cart_price {
  font-size: 17px;
  font-weight: 400;
  color: var(--bs-dark);
  flex-shrink: 0;
}

.navbar_right .search_bar {
  margin: 0 29px 0 23px;
}

.search_bar .form-control {
  font-size: 13px;
  width: 251px;
  border-radius: 20px;
  background-color: var(--bs-transparent);
  border: 1px solid rgb(112 112 112 / 23%);
  padding: 8px 45px 8px 15px;
  margin: 0;
}

.search_bar .btn-search {
  position: absolute;
  right: 16px;
  top: 10px;
  padding: 0;
}

/* ============================================= */
/* !!!!            HEADER STYLE             !!!! */
/* ============================================= */
.navbar .navbar-brand {
  padding: 17px 0;
}

nav.navbar .navbar-nav {
  row-gap: 10px;
}

nav.navbar .navbar-nav li {
  border: 0;
  margin: 0;
  padding: 0 8px;
  background-color: var(--bs-transparent);
}

.navbar-nav .nav-link {
  position: relative;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  padding: 15px 0 13px;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--bs-dark);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: var(--bs-dark);
}

.navbar-light .navbar-nav .nav-link:active,
.navbar-light .navbar-nav li.active .nav-link {
  font-weight: bold;
}

nav.navbar .navbar-nav li .nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background-color: var(--bs-dark);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

nav.navbar .navbar-nav li:hover .nav-link::after,
nav.navbar .navbar-nav li.active .nav-link::after {
  opacity: 1;
  visibility: visible;
}

.home_link {
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  line-height: 22px;
  font-weight: 700;
  color: var(--bs-dark);
  padding: 0 20px 0 0;
  margin-right: 20px;
  border-right: 1px solid var(--bs-dark);
}

.home_link:hover,
.home_link:focus {
  color: var(--bs-dark);
}

.home_link svg {
  margin-right: 7px;
}

/* ============================================= */
/* !!!!                FOOTER               !!!! */
/* ============================================= */
.footer {
  background-color: var(--bs-primary);
  background-size: 50px;
  color: var(--bs-white);
  padding: 0;
  z-index: 9;
}

.footer a,
.footer h6 {
  color: var(--bs-white);
}

.footer h6 {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 0 0 18px;
}

/* !!!! Footer Top Block !!!! */
.footer_top {
  padding: 84.5px 0 49px;
}

/* !!!! Footer Middle Block !!!! */
.footer_middle {
  padding: 0 0 52.5px;
}

.footer_navbar div a:not(:last-child) {
  margin: 0 0 12px;
}

.footer_navbar a {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  line-height: 20px;
  color: #70c0c7;
  padding: 0;
}

.footer_navbar a:hover,
.footer_navbar a:hover h6 {
  color: var(--bs-secondary);
}

.footer .i-call {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: var(--bs-secondary);
  margin: 0 0 11px;
  display: inline-block;
  transition: var(--bs-base-transition);
}

.footer .i-call:hover {
  color: var(--bs-white);
}

.social_media ul li:not(:first-child) {
  margin-left: 25px;
}

.social_media ul li a {
  opacity: 0.5;
  transition: var(--bs-base-transition);
}

.social_media ul li a:hover {
  opacity: 1;
}

/* !!!! Footer Bottom Block !!!! */
.footer_bottom {
  padding: 41px 0 31px;
}

.footer_bottom ul li {
  margin-bottom: 10px;
}

.footer_bottom ul li:not(:last-child) {
  margin-right: 26px;
}

.footer_bottom ul li a {
  font-weight: 300;
  text-decoration: underline;
  font-size: 16px;
  line-height: 20px;
}

.footer_bottom p {
  font-size: 14px;
  line-height: 22px;
  --bs-text-opacity: 0.4;
}

@media (min-width: 1440px) {
  .footer .i-call {
    font-size: 28px;
  }
}

/* ================================================= */
/* !!!!          All Page's Common Style        !!!! */
/* ================================================= */
/* !!!!  swiper  !!!! */
.swiper-container [class*="swiper-button-"] {
  width: 52px;
  height: 52px;
  background-color: var(--bs-transparent);
  border: 1px solid var(--bs-gray-100);
  border-radius: 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
}

.swiper-container [class*="swiper-button-"]::after {
  display: none;
}

.swiper-container [class*="swiper-button-"].swiper-button-disabled {
  opacity: 1;
}

.swiper-button-prev.swiper-button-disabled {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.79' height='14.726' viewBox='0 0 23.79 14.726'%3E%3Cg transform='translate(0.846 0.846)' opacity='0.5'%3E%3Cline x1='21.413' transform='translate(0.931 6.517)' fill='none' stroke='%23707070' strokeLinecap='round' stroke-width='1.2'/%3E%3Cline x1='5.586' y2='6.517' transform='translate(0 0)' fill='none' stroke='%23707070' strokeLinecap='round' stroke-width='1.2'/%3E%3Cline x1='5.586' y1='6.517' transform='translate(0 6.517)' fill='none' stroke='%23707070' strokeLinecap='round' stroke-width='1.2'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.swiper-button-prev {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.79' height='14.726' viewBox='0 0 23.79 14.726'%3E%3Cg transform='translate(0.846 0.846)'%3E%3Cline x1='21.413' transform='translate(0.931 6.517)' fill='none' stroke='%23004e6d' strokeLinecap='round' stroke-width='1.2'/%3E%3Cline x1='5.586' y2='6.517' transform='translate(0 0)' fill='none' stroke='%23004e6d' strokeLinecap='round' stroke-width='1.2'/%3E%3Cline x1='5.586' y1='6.517' transform='translate(0 6.517)' fill='none' stroke='%23004e6d' strokeLinecap='round' stroke-width='1.2'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.swiper-button-next.swiper-button-disabled {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.79' height='14.726' viewBox='0 0 23.79 14.726'%3E%3Cg transform='translate(0.6 0.846)'%3E%3Cline x2='21.413' transform='translate(0 6.517)' fill='none' stroke='%23707070' strokeLinecap='round' stroke-width='1.2'/%3E%3Cline x2='5.586' y2='6.517' transform='translate(16.758 0)' fill='none' stroke='%23707070' strokeLinecap='round' stroke-width='1.2'/%3E%3Cline y1='6.517' x2='5.586' transform='translate(16.758 6.517)' fill='none' stroke='%23707070' strokeLinecap='round' stroke-width='1.2'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.swiper-button-next {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.79' height='14.726' viewBox='0 0 23.79 14.726'%3E%3Cg transform='translate(0.6 0.846)'%3E%3Cline x2='21.413' transform='translate(0 6.517)' fill='none' stroke='%23004e6d' strokeLinecap='round' stroke-width='1.2'/%3E%3Cline x2='5.586' y2='6.517' transform='translate(16.758 0)' fill='none' stroke='%23004e6d' strokeLinecap='round' stroke-width='1.2'/%3E%3Cline y1='6.517' x2='5.586' transform='translate(16.758 6.517)' fill='none' stroke='%23004e6d' strokeLinecap='round' stroke-width='1.2'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.swiper-pagination .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  border: 1px solid var(--bs-primary);
  opacity: 1;
  background: var(--bs-white);
}

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--bs-primary);
}

/* !!! pagination !!! */
.pagination {
  margin: 50px 0 0;
  row-gap: 10px;
}

.pagination .page-item:not(:last-child) {
  margin-right: 10px;
}

.pagination .page-link {
  background-color: var(--bs-transparent);
  padding: 4px 5px 0;
  font-weight: 400;
  font-size: 18px;
  color: var(--bs-primary);
  margin: 0;
  outline: 0;
  box-shadow: none;
  width: 42px;
  height: 38px;
  border: 1px solid rgba(0, 78, 109, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .page-item.active .page-link {
  background-color: var(--bs-primary);
  color: var(--bs-white);
  border-color: var(--bs-primary);
}

/* .pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link {
  color: var(--bs-secondary);
  display: flex;
  align-items: center;
  text-transform: uppercase;
} */

.pagination .page-item:last-child .page-link {
  flex-direction: row-reverse;
}

.pagination .page-item.active .page-link .visually-hidden {
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  position: absolute !important;
}

.pagination .page-item .page-link .visually-hidden {
  position: relative !important;
  clip: inherit !important;
  overflow: visible !important;
  clip: inherit !important;
  width: auto !important;
  height: auto !important;
}

.page_link_prev .page-link {
  width: 103px;
}

.page_link_next .page-link {
  width: 71px;
}

.pagination .page-item:last-child .page-link span:first-child,
.pagination .page-item:first-child .page-link span:first-child {
  display: none;
  /* width: 15px;
  background-repeat: no-repeat;
  height: 15px;
  font-size: 0;
  background-position: center;
  display: inline-block; */
}

/* .pagination .page-item:last-child .page-link span:first-child {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3.987' height='7.987' viewBox='0 0 3.987 7.987'%3E%3Cg transform='translate(-1073.706 -2287.473)'%3E%3Cline x2='3' y2='3.5' transform='translate(1074.2 2287.967)' fill='none' stroke='%23004e6d' strokeLinecap='round' stroke-width='0.7'/%3E%3Cline y1='3.5' x2='3' transform='translate(1074.2 2291.467)' fill='none' stroke='%23004e6d' strokeLinecap='round' stroke-width='0.7'/%3E%3C/g%3E%3C/svg%3E%0A");
  margin: 0 7px 0 0;
}

.pagination .page-item:first-child .page-link span:first-child {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3.987' height='7.987' viewBox='0 0 3.987 7.987'%3E%3Cg transform='translate(1077.693 2295.46) rotate(180)'%3E%3Cline x2='3' y2='3.5' transform='translate(1074.2 2287.967)' fill='none' stroke='%23004e6d' strokeLinecap='round' stroke-width='0.7'/%3E%3Cline y1='3.5' x2='3' transform='translate(1074.2 2291.467)' fill='none' stroke='%23004e6d' strokeLinecap='round' stroke-width='0.7'/%3E%3C/g%3E%3C/svg%3E%0A");
  margin: 0 0 0 7px;
} */

/* !!!! popover !! !! */
.popover {
  background-color: var(--bs-gray-200);
  border: 1px solid rgba(3, 64, 90, 0.05);
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.09));
}

.bs-popover-bottom>.popover-arrow::before {
  border-bottom-color: rgba(3, 64, 90, 0.05);
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.09));
}

.bs-popover-bottom>.popover-arrow::after {
  border-bottom-color: var(--bs-gray-200);
}

.popover-body {
  padding: 10px 12px;
}

.popover-body .link-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: var(--bs-secondary);
  display: block;
}

.popover-body .link-item:hover {
  color: var(--bs-primary);
}

.popover-body .link-item:not(:last-child) {
  margin: 0 0 10px;
}

/* !!!! information_block !!!! */
.information_block {
  position: relative;
  padding: 12px 48px;
}

.information_block svg {
  position: absolute;
  left: 15px;
  top: 13px;
}

.information_block p {
  line-height: 20px;
}

.information_block p a,
.information_block p a:not([href]):not([class]) {
  color: var(--bs-primary);
}

.information_block p strong {
  font-weight: 500;
}

/* ============================================= */
/* !!!!          Programs Page Style        !!!! */
/* ============================================= */
.all_program_page {
  position: relative;
  padding: 70px 0;
}

/* !!! cpd_programs_filter !!! */
.cpd_programs_filter .filter_accordion.accordion>.accordion-item {
  border: 0;
}

.filter_accordion.accordion>.accordion-item>.accordion-collapse>.accordion-body {
  padding: 0;
}

@media (min-width: 768px) {
  .filter_accordion.accordion>.accordion-item>.accordion-collapse.collapse {
    display: block;
  }

  .filter_accordion.accordion>.accordion-item>.accordion-header {
    display: none;
  }
}

.cpd_programs_filter .accordion {
  max-width: 300px;
  margin: 15px 0 0 0;
}

.cpd_programs_filter .accordion .accordion-item {
  background-color: var(--bs-transparent);
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid var(--bs-gray-200);
}

.cpd_programs_filter .accordion .accordion-item .form-check .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.788' height='7.77' viewBox='0 0 10.788 7.77'%3E%3Cpath id='Icon_feather-check' data-name='Icon feather-check' d='M14.667,9,8.708,14.959,6,12.25' transform='translate(-4.939 -7.939)' fill='none' stroke='%23e6ab11' strokeLinecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E%0A");
  border-color: var(--bs-primary);
}

.cpd_programs_filter .accordion .accordion-item .link-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--bs-gray-dark);
  cursor: pointer;
  display: block;
}

.cpd_programs_filter .accordion .accordion-item li.active .link-item,
.cpd_programs_filter .accordion .accordion-item .link-item:hover {
  color: var(--bs-primary);
}

.cpd_programs_filter .accordion .accordion-item .accordion-button {
  background-color: var(--bs-transparent);
  font-weight: 400;
  font-size: 16px;
  color: var(--bs-dark);
  padding: 9px 20px;
  border-radius: 0;
  border: 0;
  box-shadow: none;
}

.cpd_programs_filter .accordion .accordion-item .accordion-button::after {
  background-size: 6px 14px;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.02' height='15.02' viewBox='0 0 7.02 15.02'%3E%3Cg id='Group_444' data-name='Group 444' transform='translate(-405.49 -243.49)'%3E%3Cline id='Line_102' data-name='Line 102' x2='6.667' y2='5.333' transform='translate(406.333 257.667) rotate(-90)' fill='none' stroke='%23808181' strokeLinecap='round' stroke-width='1.2'/%3E%3Cline id='Line_103' data-name='Line 103' x1='6.667' y2='5.333' transform='translate(406.333 251) rotate(-90)' fill='none' stroke='%23808181' strokeLinecap='round' stroke-width='1.2'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.cpd_programs_filter .accordion .accordion-item .accordion-button:not(.collapsed)::after{
  transform: rotate(90deg);
}
.order_data_listing_block .accordion .accordion-button:not(.collapsed)::after{
  transform: rotate(0deg);
}
.order_data_listing_block .accordion .accordion-button.collapsed::after{
  transform: rotate(180deg);
}

/* .cpd_programs_content h1 {
  margin: 0 0 32px 0;
} */
.cpd_programs_content .cpd_head {
  column-gap: 30px;
  row-gap: 0;
}

/* .cpd_programs_content .downloadpdf {
    margin: 0 0 30px 0;
} */
.cpd_programs_content .search_block {
  width: 100%;
  max-width: 510px;
}

.cpd_programs_content .search_block .form-control {
  border-radius: 25px;
  border: 1px solid var(--bs-gray-200);
  padding: 12px 20px 10px 25px;
  font-weight: 400;
  font-size: 14px;
  color: var(--bs-gray-dark);
  margin: 0;
}

.cpd_programs_content .search_block .search_group {
  margin: 0 0 13px;
}

.cpd_programs_content .search_block .search_group .btn-search {
  position: absolute;
  margin: 0;
  padding: 0;
  right: 25px;
  top: 15px;
}

.cpd_programs_content .search_block .date_filed {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.793' height='16.27' viewBox='0 0 14.793 16.27'%3E%3Cg transform='translate(-3.75 -2.25)'%3E%3Cpath d='M5.977,6H16.316a1.477,1.477,0,0,1,1.477,1.477V17.816a1.477,1.477,0,0,1-1.477,1.477H5.977A1.477,1.477,0,0,1,4.5,17.816V7.477A1.477,1.477,0,0,1,5.977,6Z' transform='translate(0 -1.523)' fill='none' stroke='%23848484' strokeLinecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cpath d='M24,3V5.954' transform='translate(-9.9)' fill='none' stroke='%23848484' strokeLinecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cpath d='M12,3V5.954' transform='translate(-3.808)' fill='none' stroke='%23848484' strokeLinecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cpath d='M4.5,15H17.793' transform='translate(0 -6.092)' fill='none' stroke='%23848484' strokeLinecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 90% 50%;
  cursor: pointer;
}

.filter_dropdown_list .form-control {
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='17.075' viewBox='0 0 20 17.075'%3E%3Cg transform='translate(0 0.075)'%3E%3Crect width='18' height='11.05' transform='translate(1 2.55)' fill='none' stroke='%23004e6d' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1'/%3E%3Cpath d='M.5,0V4.25' transform='translate(4.5 0.425)' fill='none' stroke='%23004e6d' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1'/%3E%3Cpath d='M.5,0V4.25' transform='translate(14.5 0.425)' fill='none' stroke='%23004e6d' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1'/%3E%3Cpath d='M0,.5H17.5' transform='translate(1.5 6.3)' fill='none' stroke='%23004e6d' stroke-miterlimit='10' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");*/
  background-repeat: no-repeat;
  background-position: 94% 50%;
  padding-right: 20px;
}

.filter_dropdown_list .form-control.date_filed::-webkit-inner-spin-button,
.filter_dropdown_list .form-control.date_filed::-webkit-calendar-picker-indicator {
  /* background-image: none;*/
  z-index: 0;
}

.date_filed::-webkit-inner-spin-button,
.date_filed::-webkit-calendar-picker-indicator {
  width: 18px;
  /* opacity: 0; */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='17.075' viewBox='0 0 20 17.075'%3E%3Cg transform='translate(0 0.075)'%3E%3Crect width='18' height='11.05' transform='translate(1 2.55)' fill='none' stroke='%23004e6d' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1'/%3E%3Cpath d='M.5,0V4.25' transform='translate(4.5 0.425)' fill='none' stroke='%23004e6d' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1'/%3E%3Cpath d='M.5,0V4.25' transform='translate(14.5 0.425)' fill='none' stroke='%23004e6d' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1'/%3E%3Cpath d='M0,.5H17.5' transform='translate(1.5 6.3)' fill='none' stroke='%23004e6d' stroke-miterlimit='10' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");
  cursor: pointer;
}

.programs_description {
  max-width: 600px;
  margin: 35px 0 25px;
}

.programs_description p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.program_card .card-body {
  padding: 15px 20px 25px 0;
}

.program_card .card-body .card-text {
  /* padding: 25px 15px; */
  min-height: 105px;
}

/* @media (min-width: 768px) {
  .program_card .card-body .card-text {
    padding: 0 0 0 15px;
  }
} */
@media (min-width: 992px) {
  .program_card .card-body .card-text {
    padding: 0 0 0 220px;
  }
}


.program_card a {
  text-decoration: none;
}

.program_card .card-title {
  margin: 0 0 17px;
  font-weight: 700;
}

.program_card .cpd_points {
  font-weight: 300;
  font-size: 16px;
  margin-left: 10px;
}

.program_cate {
  border-radius: 3px;
  background-color: #70c0c7;
  padding: 15px;
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  .program_cate {
    position: absolute;
    top: 0;
    left: 0;
    width: 196px;
    height: 117px;
    border-radius: 3px 0 3px 0;
    margin-bottom: 0;
  }

  .program_cate2 {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 80px;
    height: 80px;
    padding: 0;
    border-radius: 4px;
    align-items: center;
  }
}

.single_program_page .program_cate img,
.cpd_programs .program_cate img {
  max-height: none;
}

.program_cate img {
  /* max-height: 50px; */
  width: auto;
}

.program_cate h3 {
  color: var(--bs-white);
  margin: 0;
  line-height: 36px;
  font-weight: 700;
}

.program_cate small {
  font-size: 22px;
  color: var(--bs-white);
  font-weight: 400;
  line-height: 20px;
}

.program_card .card-body ul {
  margin: 0 0 6px;
  row-gap: 10px;
}

.program_card .card-body ul li {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--bs-dark);
}

.program_card ul li:not(:last-child) {
  margin-right: 20px;
}

.program_card ul li .program_time svg {
  margin-right: 8.5px;
}

/* .program_card .card-body ul li.cpd_topics {
  padding: 0 5px;
  text-align: center;
} */

.program_card .card-body ul li.cpd_topics img {
  margin: 0 4.5px;
}

.program_card .card-body ul li.cpd_topics img:first-child {
  margin-left: 0;
}

.program_card .card-body ul li.cpd_topics img:last-child {
  margin-right: 0;
}

.program_card .card-body ul li.cpd_location {
  position: relative;
  padding: 0 0 0 18px;
}

.program_card .card-body ul li.cpd_location svg {
  position: absolute;
  left: 0;
  top: 0;
}

.program_card .address {
  /* max-width: 356px; */
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  position: relative;
  padding: 0 0 0 31px;
  margin: 0;
  color: var(--bs-dark);
}

.program_card .address svg {
  position: absolute;
  left: 0;
  top: 0;
}

.program_card .price_sale {
  /* max-width: 320px; */
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: #70c0c7;
  font-style: italic;
}

.program_card .price_sale svg {
  margin-right: 10px;
}

.program_card .card-body ul+.author_groups {
  margin-top: 10px;
}

.author_groups {
  padding: 15px 0 0;
}

.author_card {
  position: relative;
  padding: 0 0 0 80px;
  min-height: 67px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.author_card img {
  position: absolute;
  left: 0;
  top: 0;
  width: 67px;
  height: 67px;
  object-fit: cover;
  border-radius: 100%;
}

.author_card p {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: var(--bs-dark);
  margin: 0 0 2px;
}

.author_card small {
  display: block;
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
  color: var(--bs-dark);
}

@media (min-width: 992px) {
  .author_groups {
    padding: 24px 16px 0;
  }
}

.adddress.small {
  max-width: 343px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  padding: 0px 0 0 26px;
  margin: 10px 0 0;
  color: var(--bs-gray-dark);
}

.program_card .program_right {
  padding: 15px 21px 23px;
  max-width: 360px;
}

.program_card .program_right h3 {
  font-weight: 700;
  /* font-size: 39px;
  letter-spacing: -0.03em;
  line-height: 46px;
  margin: 0 0 10px; */
}

.discount_price {
  font-weight: normal;
  font-style: italic;
  font-size: 20px;
  line-height: 32px;
  color: #70c0c7;
  margin-right: 15px;
  text-decoration: line-through;
}

.program_right .form_check_group span {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: var(--bs-primary);
  margin: 0 13px 10px 0;
}

.program_right .form_check_group span:last-child {
  margin-right: 0;
}

.program_right .form_check_group .form-check-inline {
  margin-right: 0;
  display: flex;
  align-items: center;
}

.program_right .form_check_group .form-check-inline:not(:last-child) {
  margin-right: 15px;
}

.program_card .btn-link {
  margin: 0 15px 0 0;
  display: inline-block;
  min-width: 151px;
  border-radius: 3px;
  background: transparent;
  border: 1px solid var(--bs-primary);
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  color: var(--bs-primary);
  padding: 7px 9px 3.5px;
  transition: all 0.3s ease-in-out;
}

.program_card .btn-link:hover {
  background-color: var(--bs-primary);
  color: var(--bs-white);
}

.program_card .program_right .label_tag {
  display: block;
  text-align: right;
  margin: 0 0 15px;
  padding: 0;
}

.program_card .program_right .btn-primary {
  min-width: 151px;
  border-radius: 3px;
  background: #fec010;
  font-weight: 700;
  font-size: 18px;
  color: #01293a;
  border: 0;
  box-shadow: inset -2px -2px 0 #e0e0e0;
  padding: 9px 9px 7px;
  transition: all 0.3s ease-in-out;
}
/*
.practice_area_interests+.btn {
  margin: 30px 0 0;
  background-color: #f0f0f0;
  border: 0;
  color: #a7a7a7;
  box-shadow: inset -2px -2px #e0e0e0;
  padding: 9px 9px 7px;
}

.practice_area_interests+.btn:hover {
  color: var(--bs-primary);
}
*/
.program_card .program_right .btn-primary:hover {
  background-color: var(--bs-secondary);
  box-shadow: inset -2px -2px 0 var(--bs-secondary-dark);
  color: var(--bs-dark);
}

.wishlist_link {
  font-weight: 400;
  text-decoration: underline;
  font-size: 16px;
  color: #977c88;
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0 0 11px;
}

.wishlist_link svg {
  margin-right: 8.5px;
}

.wishlist_link_add svg path {
  fill: var(--bs-primary);
}

@media (min-width: 576px) {
  .program_card .program_right h3 {
    font-size: 30px;
    letter-spacing: -0.03em;
    line-height: 46px;
    margin: 0 0 10px;
  }

}

/* ==================================================== */
/* !!!!             Wishlist Page Style            !!!! */
/* ==================================================== */
.wishlist_right_block .program_card .card-body .card-text {
  /* padding-left: 16px;*/
}

/* ==================================================== */
/* !!!!          Single Programs Page Style        !!!! */
/* ==================================================== */
.single_program_page {
  position: relative;
  padding: 50px 0;
}

.related_courses_wrapper {
  background-size: 48px;
  background-color: var(--bs-light);
  padding: 77px 0 0 0;
}

@media (min-width: 992px) {
  .cpd_single_programs_content {
    max-width: 460px;
  }
}

@media (min-width: 1200px) {
  .cpd_single_programs_content {
    max-width: 660px;
  }
}

@media (min-width: 1470px) {
  .cpd_single_programs_content {
    max-width: 760px;
  }
}

.single_program_page.leo_product_programs .cpd_single_programs_content {
  min-height: 1000px;
}

.cpd_single_programs_content hr {
  /* background-color: var(--bs-gray-400);
    border-color: var(--bs-gray-400);*/
  margin: 50px 0;
}

.single_programs_head {
  position: relative;
  /* padding: 0 0 0 185px; */
  margin: 0 0 26px;
  /* min-height: 101px; */
}

.single_programs_head img {
  position: absolute;
  left: 0;
  top: 0;
  max-width: 160px;
  max-height: 101px;
  border-radius: 4px;
}

.cpd_points_mode_type_block {
  border-radius: 4px;
  background: var(--bs-light);
  margin: 0 0 21px;
}

.cpd_points_mode_type_txt {
  width: 100%;
  padding: 19px 15px 19px 30px;
  grid-row-gap: 3px;
  row-gap: 3px;
  display: flex;
  flex-direction: column;
}

.cpd_points_mode_type_txt li,
.cpd_points_mode_type_txt .cpd_program_type p {
  font-weight: 300;
  /* text-decoration: underline;*/
  font-size: 18px;
  line-height: 26px;
  color: var(--bs-primary);
}

.cpd_points_mode_type_img .program_cate {
  position: relative;
  height: 100%;
  border-radius: 4px 0 0 4px;
}

.cpd_points_mode_type_block .item_label {
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  color: var(--bs-primary);
  text-transform: uppercase;
  max-width: 185px;
  width: 100%;
  display: inline-block;
}

@media (min-width: 1470px) {
  .cpd_points_mode_type_block .item_label {
    max-width: 215px;
  }
}

.cpd_point_item ul li:empty {
  display: none;
}

.cpd_point_item ul li:not(:last-child) {
  margin-right: 5px;
}

.cpd_point_item ul li:last-child {
  margin-left: 13px;
  font-size: 16px;
  color: var(--bs-primary);
}

.single_program_page .single_programs_head ul li,
.single_program_page .address_block li {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--bs-gray-dark);
}

.single_program_page .address_block {
  margin: 0 0 38px;
}

.single_program_page .address_block li {
  position: relative;
  padding: 0px 0 0 26px;
}

.single_program_page .address_block li.cpd_topics {
  padding: 0 30px;
}

.single_program_page .address_block li.cpd_topics img {
  margin: 0 4.5px;
}

.single_program_page .address_block li.cpd_topics img:first-child {
  margin-left: 0;
}

.single_program_page .address_block li.cpd_topics img:last-child {
  margin-right: 0;
}

.single_program_page .address_block li svg {
  position: absolute;
  left: 0;
  top: 0;
}

.author_group {
  max-width: 100%;
  margin: 0 0 35px;
}

.single_program_page .price_sale {
  font-weight: 500;
  font-size: 14px;
  color: var(--bs-primary);
  margin: 0 0 22px
}

.cpd_single_programs_content .author_card {
  padding: 0;
  min-height: inherit;
}

.cpd_single_programs_content .author_card .author_card_head {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 0 0 20px 115px;
  min-height: 120px;
}

.cpd_single_programs_content .author_card img {
  width: 100px;
  height: 100px;
}

.cpd_single_programs_content .author_card p {
  font-size: 24px;
  line-height: 32px;
}

.cpd_single_programs_content .author_card_content p {
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
}

.cpd_single_programs_content .author_card_content a {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: var(--bs-primary);
  text-decoration: underline;
}

.single_program_page .programs_description {
  margin: 25px 0;
  text-align: left;
}

.single_program_page .programs_description p,
.single_program_page .programs_description ul li {
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  color: var(--bs-dark);
}

.cpd_single_programs_content .display-5 {
  margin: 0 0 24px;
}

.single_program_page .programs_description {
  word-break: break-word;
  white-space: normal;
}

.single_program_page .programs_description ul {
  /* list-style: none;
    padding: 0;*/
}

.single_program_page .programs_description ul li {
  margin: 0;
}

.single_program_page .programs_description table,
.single_program_page .programs_description table * {
  border: 0;
  padding: 0;
}

.single_program_page .programs_description table ul ul {
  padding: 0 0 0 20px;
}

/* !!!! program single accordion wrapper !!!! */
.cpd_single_programs_content .accordion .accordion-item {
  border: 0;
}

.cpd_single_programs_content .accordion .accordion-item .accordion-button {
  padding: 11.5px 0;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  color: var(--bs-dark);
  background: transparent;
  border-bottom: 1px solid var(--bs-primary);
  border-radius: 0;
  box-shadow: none;
}

.cpd_single_programs_content .accordion .accordion-item .accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='9.121' viewBox='0 0 18 9.121'%3E%3Cg transform='translate(0 2.068) rotate(-90)'%3E%3Cpath d='M0,.654H.971' transform='translate(0 7.846)' fill='none' stroke='%23004e6d' stroke-miterlimit='10' stroke-width='2'/%3E%3Cpath d='M0,0,7,7,0,14' transform='translate(-6.346 1.5)' fill='none' stroke='%23004e6d' stroke-miterlimit='10' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
  transform: rotate(-180deg);
  background-position: center;
}

.cpd_single_programs_content .accordion .accordion-item .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='9.121' viewBox='0 0 18 9.121'%3E%3Cg transform='translate(0 2.068) rotate(-90)'%3E%3Cpath d='M0,.654H.971' transform='translate(0 7.846)' fill='none' stroke='%23004e6d' stroke-miterlimit='10' stroke-width='2'/%3E%3Cpath d='M0,0,7,7,0,14' transform='translate(-6.346 1.5)' fill='none' stroke='%23004e6d' stroke-miterlimit='10' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E%0A");
  transform: rotate(0deg);
}

.cpd_single_programs_content .accordion .accordion-item .accordion-body {
  padding: 30px 0;
}

.cpd_single_programs_content .accordion .programs_description {
  margin: 0;
  max-width: 100%;
}

.cpd_single_programs_content .accordion .author_group {
  margin: 0;
}

.cpd_single_programs_content .accordion .author_card {
  margin-bottom: 30px;
}

.sessions-accordion .author_group,
.sessions-accordion .author_group .author_card {
  margin: 0;
}

.sessions-accordion .accordion-body>div+div {
  margin-top: 50px;
}

.sessions-accordion .accordion-body .programs_description p>br {
  display: none;
}

/* !!!! right sidebar program single page !!!! */
.single_rightside {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 420px;
  width: 100%;
}

.single_rightside_inner {
  background: var(--bs-white);
}

@media (min-width: 768px) {
  .single_rightside_inner {
    border-radius: 3px;
    border: 1px solid var(--bs-primary);
    box-shadow: inset -4px -4px 0 var(--bs-light);
  }

  .cpd_single_programs_content .discount_price {
    margin-right: 3px;
    text-align: right;
    float: right;
  }

  .navbar_right .css-b62m3t-container {
    min-width: 251px;
  }
}

.share_colleagues_link {
  font-weight: 300;
  text-decoration: underline;
  font-size: 18px;
  line-height: 26px;
  color: var(--bs-primary);
  margin: 22px 0 0;
}

.share_colleagues_link svg {
  margin-right: 13px;
}

.program_right_cart {
  position: relative;
  padding: 25px 25px 30px;
}

/* .program_right_cart_head {
  margin: 0 0 25px;
} */
.program_right_cart_head h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 30px;
  max-width: 220px;
  padding-right: 20px;
}

.program_right_cart_head .program_price {
  font-weight: 700;
  font-size: 30px;
  letter-spacing: -0.03em;
  line-height: 26px;
}

.program_right_cart_head p {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--bs-gray-dark);
}

.program_right_cart_head .program_cate {
  position: relative;
  top: auto;
  left: auto;
}

.short_promotion_message {
  margin: 0 0 10px;
}

.short_promotion_message .label_tag {
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  color: #fe1010;
}

.program_right_cart .btn.btn-primary {
  margin-top: 25px;
}

.program_right_cart .list_block {
  border-top: 1px solid #bedee1;
  padding: 25px 0;
}

.program_right_cart .list_block p,
.program_right_cart .list_block .form-label {
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  color: var(--bs-secondary);
  margin: 0;
}

.program_right_cart .list_block .price_sale {
  color: #fe1010;
  margin: 7px 0 20px;
}

.program_right_cart .list_block .price_sale svg {
  margin-right: 10px;
}

.single_rightside .product_code {
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: #70c0c7;
}

.program_right_cart .list_block small {
  font-size: 20px;
  line-height: 32px;
  color: var(--bs-primary);
  font-weight: 400;
}

.program_right_cart .list_block small svg {
  margin-right: 10px;
  margin-top: 6px;
}

.quantity_group .form-control {
  width: 64px;
  border-radius: 3px;
  background-color: var(--bs-white);
  border: 1px solid var(--bs-primary);
  padding: 8px 25px 8px 13px;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: var(--bs-primary);
  margin: 0;
}

.quantity_group .quantity_changer {
  background-color: var(--bs-transparent);
  position: absolute;
  top: 0;
  right: 14px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.quantity_group .quantity_changer span {
  cursor: pointer;
  display: inline-block;
  line-height: 12px;
}

.quantity_group .quantity_changer span svg {
  width: 14px;
}

.print_info {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #004e6d;
  margin: 5px 0 0;
}

.print_info:hover,
.print_info:focus {
  text-decoration: underline;
}

.print_info svg {
  margin-right: 13px;
}

.quantity_block .form_check_group {
  margin: 0 0 20px;
}

.quantity_block .form_check_group .form-check-inline {
  margin-right: 0;
}

.quantity_block .form_check_group .form-check-inline:not(:last-child) {
  margin-right: 15px;
}

.quantity_block .form_check_group span {
  font-size: 14px;
  line-height: 22px;
  margin-right: 17px;
}

.quantity_group input[type=number] {
  -moz-appearance: textfield;
}

.quantity_group input[type=number]::-webkit-inner-spin-button,
.quantity_group input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.program_right_cart .quantity_group label.form-label {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  margin-right: 13px;
}

.quantity_block .form-check {
  margin: 0 12px 0 0;
  display: flex;
  align-items: center;
}

.program_right_cart .btn.btn_cart {
  max-width: 261px;
  margin-left: 12px;
  width: 100%;
}

.cpd_single_programs_content .single_rightside hr {
  opacity: 1;
  background-color: var(--bs-gray-100);
  margin: 25px 0 0;
}

.cpd_total_point_block {
  padding: 25px 25px 39px;
}

.cpd_total_point_block p {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--bs-secondary);
  margin: 0 0 8px;
}

.cpd_total_point_block ul {
  margin: 0 0 45px;
}

.cpd_total_point_block ul .list-group-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: var(--bs-gray-dark);
}

/* !!! publication_details_wrapper !!! */
.publication_details_wrapper {
  position: relative;
  margin: 90px 0 0 0;
}

.cpd_single_programs_content .accordion .accordion-item .accordion-body .publication_details_wrapper {
  margin: 0;
}

.publication_details_wrapper .publication_details_card {
  position: relative;
  padding: 30px 20px;
  margin: 0 0 15px;
}

.cpd_single_programs_content .accordion .accordion-item .publication_details_wrapper .publication_details_card {
  padding: 30px 20px;
}

.publication_details_wrapper h2 {
  margin: 0 0 31px;
}

.publication_details_card ul {
  max-width: 700px;
}

.publication_details_card ul li {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: var(--bs-dark);
}

.publication_details_card ul li strong {
  font-weight: 500;
  color: var(--bs-secondary);
}

/* !!! author_testimonial_wrapper !!! */
.author_testimonial_wrapper {
  position: relative;
  margin: 0 0 65px;
  padding: 40px 40px 45px 36px;
}

.author_testimonial_wrapper h2 {
  margin: 0 0 -30px;
}

.author_testimonial_wrapper .swiper-container {
  padding: 52px 0 0
}

.author_testimonial_wrapper .swiper-container [class*="swiper-button-"] {
  top: 0;
}

.author_testimonial_wrapper .swiper-container .swiper-button-prev {
  left: auto;
  right: 65px;
}

.author_testimonial_wrapper .swiper-container .swiper-button-next {
  right: 0;
}

.quote_author_card {
  min-height: 100px;
}

.quote_author_card .quote_author_card_content {
  position: relative;
  padding: 25px 0 0 115px;
}

.quote_author_card .quote_description {
  max-width: 355px;
}

.quote_author_card img {
  position: absolute;
  left: 0;
  top: 0;
  width: 90px;
  height: 90px;
  border-radius: 100%;
  object-fit: cover;
}

.quote_author_card .author_name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
}

.quote_author_card .author_role {
  display: block;
  font-size: 13px;
  line-height: 15px;
  margin: 0 0 20px;
}

.quote_author_card p {
  font-size: 16px;
  line-height: 24px;
}

/* !!! related_courses_wrapper !!! */
.publication_details_wrapper+.related_courses_wrapper {
  margin-top: 60px;
}

.related_courses_wrapper h2 {
  margin: 0 0 36px;
}

.related_courses_wrapper .swiper-container {
  padding: 0 0 130px;
}

.related_courses_wrapper .swiper-container .swiper-wrapper {
  display: flex;
  flex-direction: row;
}

.related_courses_wrapper .swiper-container .swiper-wrapper .swiper-slide {
  height: auto;
}

.related_courses_wrapper .swiper-container .swiper-wrapper .swiper-slide .courses_card,
.related_courses_wrapper .swiper-container .swiper-wrapper .swiper-slide .courses_card .card {
  height: 100%;
}

.related_courses_wrapper .swiper-container [class*="swiper-button-"] {
  bottom: 0;
  top: auto;
}

.related_courses_wrapper .swiper-container .swiper-button-prev {
  left: 31%;
}

.related_courses_wrapper .swiper-container .swiper-button-next {
  right: 31%;
}

.courses_card {
  outline: none;
  text-decoration: none;
}

.courses_card .courses_card_content {
  padding: 30px 20px;
}

.courses_card .courses_card_content h4 {
  max-width: 250px;
}

.courses_card .courses_card_content p {
  max-width: 285px;
  font-size: 14px;
  line-height: 20px;
}

.courses_card .courses_card_content ul {
  margin: 30px 0 0;
  border: 0;
}

.courses_card .courses_card_content ul li {
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--bs-gray-dark);
  padding: 0 0 0 20px;
}

.courses_card .courses_card_content ul li svg {
  position: absolute;
  left: 0;
}

.courses_card .courses_card_content ul li span {
  font-size: 9px;
  line-height: 11px;
  color: var(--bs-gray-200);
  display: block;
}

/* =================================================== */
/*     |!|!|!|!|  ADMIN PAGE STYLE CSS  |!|!|!|!|     */
/* =================================================== */
.admin_page {
  position: relative;
  padding: 50px 0;
}

/* !!! Admin Left Sidebar !!! */
.admin_listing_block {
  max-width: 307px;
  border-radius: 4px;
  background: var(--bs-white);
  border: 1px solid var(--bs-light);
  box-shadow: 6px 6px 0px #e5edf1;
}

.admin_page .admin_listing_block li:not(:last-child) {
  border-bottom: 1px solid #f0f4f6;
}

.admin_listing_block li a {
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 18px 30px;
  font-weight: 400;
  color: var(--bs-primary);
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.admin_page .admin_listing_block li:first-child a {
  border-radius: 4px 4px 0 0;
}

.admin_page .admin_listing_block li:last-child a {
  border-radius: 0 0 4px 4px;
}

.admin_listing_block li.active a,
.admin_listing_block li:hover a {
  background-color: var(--bs-dark);
  color: var(--bs-white);
}

.admin_listing_block li a svg {
  margin: 0 20px 0 0;
}

.admin_listing_block li.active a svg path,
.admin_listing_block li.active a svg line,
.admin_listing_block li.active a svg g,
.admin_listing_block li.active a svg rect,
.admin_listing_block li.active a svg circle,
.admin_listing_block li:hover a svg rect,
.admin_listing_block li:hover a svg g,
.admin_listing_block li:hover a svg path,
.admin_listing_block li:hover a svg line,
.admin_listing_block li:hover a svg circle {
  stroke: var(--bs-white)
}

/* .admin_listing_block li.active a .my_events_icon rect {
  stroke: none;
} */

.admin_listing_block li:hover a svg.logout_icon path {
  fill: var(--bs-white);
}

.admin_sidebar_mobile .admin_listing_block {
  border: 0;
  border-bottom: 2px solid var(--bs-primary);
  background: transparent;
  box-shadow: none;
  flex-direction: row;
  border-radius: 0;
  max-width: 100%;
  justify-content: space-between;
  margin: 0 0 40px;
}

.admin_sidebar_mobile .admin_listing_block li {
  border: 0;
  margin: 0;
}

.admin_sidebar_mobile .admin_listing_block li:not(:last-child) {
  border: 0;
  margin: 0;
}

.admin_sidebar_mobile .admin_listing_block .link-item {
  font-size: 0;
  padding: 10px 15px;
  border-radius: 3px 3px 0 0;
}

.admin_sidebar_mobile .admin_listing_block .link-item svg {
  margin: 0;
  height: 18px;
  width: auto;
}

@media (min-width: 1470px) {
  .admin_listing_block li a {
    font-size: 22px;
    line-height: 26px;
  }
}

@media (max-width: 575px) {
  .admin_sidebar_mobile .admin_listing_block .link-item {
    padding: 10px;
  }

  .admin_sidebar_mobile .admin_listing_block .link-item svg {
    width: 15px;
  }
}

/* !!! Admin Right Content Block !!! */
.admin_right_content_block .admin_head {
  margin: 0 0 16px;
}

.admin_right_content_block .admin_events_list_card+.admin_events_list_card .admin_head {
  margin: 60px 0 45px;
}

.cpd_point_data_listing_block+.admin_head {
  margin-top: 94px;
}

.cpd_point_data_listing_block.manually-addded {
  position: relative;
}

.manually-addded>div::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 30px 30px 0;
  border-color: transparent var(--bs-dark) transparent transparent;
  right: 0;
  top: 0;
  position: absolute;
  z-index: 99;
}

.admin_cpd_counts_card {
  height: 121px;
  border-radius: 4px;
  background: var(--bs-white);
  border: 1px solid var(--bs-light);
  box-shadow: 6px 6px 0px #e5edf1;
  margin: 0 0 30px;
  padding: 10px;
}

.admin_cpd_counts_card .counts {
  font-weight: 400;
  font-size: 50px;
  line-height: 42px;
  color: var(--bs-primary);
  margin: 0 20px 0 0;
}

.admin_cpd_counts_card h4 {
  font-weight: 300;
  line-height: 27px;
  color: #70c0c7;
  margin: 0;
}

.admin_cpd_counts_card ul {
  column-gap: 7px;
}

.admin_cpd_counts_card .count-cpd {
  font-weight: normal;
  font-size: 46px;
  line-height: 52px;
  color: var(--bs-primary);
}

.profile_detail_head {
  margin: 0 0 16px;
}

.manually_add_point {
  font-weight: 300;
  text-decoration: underline;
  font-size: 14px;
  line-height: 18px;
  color: var(--bs-primary);
  cursor: pointer;
}

.manually_add_point svg {
  margin-right: 7px;
}

.profile_detail_head .edit_profile,
.profile_detail_head .edit_password {
  text-decoration: underline;
  font-size: 12px;
  line-height: 19px;
  color: var(--bs-primary);
  cursor: pointer;
}

.profile_detail_head .edit_profile svg,
.profile_detail_head .edit_password svg {
  margin-right: 9px;
}

.profile_detail_head .edit_profile {
  margin: 0 29px 0 0;
}

.profile_detail {
  border-radius: 4px;
  background: var(--bs-white);
  border: 1px solid var(--bs-light);
  box-shadow: 6px 6px 0px #e5edf1;
  padding: 30px;
}

.profile_detail_content ul li {
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  color: var(--bs-primary);
  margin: 0 0 10px;
  word-break: break-word;
  white-space: normal;
}

.profile_detail_content ul li span {
  font-weight: 700;
  margin-right: 10px;
}

.your_referal_code {
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  color: var(--bs-primary);
}

.your_referal_code strong {
  font-weight: 400;
  font-size: 34px;
  line-height: 40px;
  margin-left: 10px;
}

.practice_area_interests {
  border-top: 1px solid #f0f4f6;
  padding: 30px 0 0;
  margin: 30px 0 0;
}

.practice_area_interests .form-check {
  margin: 0 0 17px 0;
  display: flex;
}

.practice_area_interests .form-check .form-check-label {
  line-height: 20px;
}

.practice_area_interests .form-check .form-check-input {
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 20px;
}

.admin_right_content_block .search_block .form-control {
  min-width: 285px;
  border-radius: 20px;
  border: 1px solid var(--bs-gray-200);
  padding: 11px 12px 11px 45px;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--bs-dark);
  margin: 0;
}

.admin_right_content_block .search_block .form-control:not(:placeholder-shown) {
  color: var(--bs-dark);
}

.admin_right_content_block .search_block .btn-search {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  height: 40px;
  min-width: 45px;
}

.admin_right_content_block .admin_head .cpd_topics li {
  border: 0;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: var(--bs-dark);
}

.admin_right_content_block .admin_head .cpd_topics li span,
.admin_right_content_block .admin_head .cpd_topics li:not(:last-child) {
  margin: 0 9px 0 0;
}

.admin_right_content_block .admin_head .cpd_topics li .btn {
  margin-left: 22px;
}

@media (min-width: 1200px) {
  .admin_cpd_counts_card .counts {
    font-size: 81px;
    line-height: 52px;
  }
}

@media (max-width: 1199.98px) {
  .admin_cpd_counts_card h4 {
    font-size: 20px;
    line-height: 21px;
  }

  .admin_cpd_counts_card .count-cpd {
    font-size: 36px;
    line-height: 42px;
  }
}

@media (max-width: 991.98px) {
  .admin_right_content_block {
    margin-top: 40px;
  }
}

@media (max-width: 575.98px) {
  .profile_detail {
    padding: 30px 20px;
  }

  .profile_detail_content ul li {
    font-size: 15px;
    line-height: 20px;
  }

  .admin_cpd_counts_card .counts {
    font-size: 40px;
  }

  .admin_cpd_counts_card h4 {
    font-size: 18px;
    line-height: 20px;
  }

  .admin_cpd_counts_card .count-cpd {
    font-size: 26px;
    line-height: 32px;
  }

  .your_referal_code {
    font-size: 16px;
    line-height: 22px;
  }

  .your_referal_code strong {
    font-size: 24px;
    line-height: 30px;
  }
}

/* !!! Admin Events Page !!! */
.admin_event_card ul li,
.admin_event_card ul li a {
  color: var(--bs-dark);
  line-height: 17px;
}

.admin_event_card {
  position: relative;
  margin: 0 0 16px;
  min-height: 350px;
}

.admin_event_card .card-body {
  padding: 26px;
}

.admin_event_card .card-body h3.card-title {
  color: var(--bs-dark);
  margin: 0 0 20px;
}

.admin_event_card .card-text ul li.registration_date,
.admin_event_card .card-text ul li.online_order,
.downloadable_material_block ul li,
.downloadable_material_block ul li a,
.downloadable_material_block>p {
  font-weight: 500;
}

.admin_event_card .card-text ul li.registration_date,
.admin_event_card .card-text ul li.online_order {
  margin: 5px 0 15px;
}

.admin_event_card .card-text ul li.live_stream_access,
.admin_event_card .card-text ul li.stream_location {
  font-weight: normal;
  font-size: 14px;
  position: relative;
  padding: 0 20px 0 21px;
}

.admin_event_card .card-text ul li.live_stream_access svg,
.admin_event_card .card-text ul li.stream_location svg {
  position: absolute;
  left: 0;
  top: 3px;
}

.downloadable_material_block {
  margin: 26px 0 0;
}

.downloadable_material_block>p {
  color: var(--bs-primary);
  margin: 0 0 15px;
}

.downloadable_material_block ul li.list-group-item {
  position: relative;
  padding: 0 0 2px 22px;
}

.downloadable_material_block ul li:not(:last-child) {
  margin: 0 0 17px;
}

.downloadable_material_block ul li svg {
  position: absolute;
  left: 0;
  top: 0;
}

.event_card_right_block {
  position: absolute;
  top: 26px;
  right: 32px;
  max-width: 171px;
  margin-right: auto;
}

.event_time_date_block {
  background-color: var(--bs-gray-light);
  border: 1px solid var(--bs-gray-200);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  margin: 0 0 36px;
  padding: 26px 10px;
}

.event_time_date_block span {
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: var(--bs-dark);
  display: block;
}

.event_time_date_block span.date_holder {
  font-weight: 500;
}

.admin_event_card .btn {
  padding: 11px 25px;
}

@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    .admin_event_card .btn {
      padding: 13px 25px 9px;
    }
  }
}

.admin_event_card .btn-outline-primary {
  margin-top: 20px;
}

/* !!!! ADMIN ORDER PAGE !!!!*/
.order_data_wrapper .order_data_head {
  margin: 0 0 18px;
  position: relative;
  overflow-x: auto;
  width: 100%;
}

.order_data_wrapper .accordion_header {
  position: relative;
  overflow-x: auto;
  width: 100%;
}

.order_head_record_block ul li:first-child {
  padding-left: 60px
}

.order_head_record_block ul li .accordion-button:not(.collapsed) {
  color: transparent;
  background-color: transparent;
  box-shadow: none;
}

.order_head_record_block ul li:first-child .accordion-button {
  background: transparent;
  position: absolute;
  left: 0;
  width: 60px;
  padding: 0 20.64px;
  box-shadow: none;
  outline: none;
}

.order_head_record_block ul li .accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.12' height='10.453' viewBox='0 0 20.12 10.453'%3E%3Cg transform='translate(-468.44 -461.44)'%3E%3Cline x1='9' y2='8.333' transform='translate(469.5 462.5)' fill='none' stroke='%23707070' strokeLinecap='round' stroke-width='1.5'/%3E%3Cline x2='9' y2='8.333' transform='translate(478.5 462.5)' fill='none' stroke='%23707070' strokeLinecap='round' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  margin: 0 auto;
  background-position: center;
}

.order_data_wrapper .order_data_head .shorting_changer {
  display: inline-flex;
  flex-direction: column;
  vertical-align: middle;
  background-color: var(--bs-transparent);
  align-items: center;
  justify-content: center;
  margin: -8px 0 0 13px;
}

.order_data_wrapper .order_data_head .shorting_changer span {
  display: inline-block;
  line-height: 6px;
}

.order_data_wrapper .order_data_head .shorting_changer span svg path {
  fill: var(--bs-secondary-light);
}

.order_data_wrapper .order_data_head ul li {
  font-weight: 500;
  font-size: 18px;
  color: var(--bs-secondary);
  padding: 19px 10px;
  white-space: nowrap;
}

.order_data_wrapper .order_data_head ul li:first-child {
  padding-left: 26px;
}

.order_data_wrapper .order_data_head ul li:first-child,
.order_data_wrapper .order_head_record_block ul li:first-child {
  width: 185px;
  min-width: 185px;
}

.order_data_wrapper .order_data_head ul li:nth-child(2),
.order_data_wrapper .order_head_record_block ul li:nth-child(2) {
  width: 165px;
  min-width: 165px;
}

.order_data_wrapper .order_data_head ul li:nth-last-child(4),
.order_data_wrapper .order_head_record_block ul li:nth-last-child(4) {
  width: 125px;
  min-width: 125px;
}

.order_data_wrapper .order_data_head ul li:nth-last-child(3),
.order_data_wrapper .order_head_record_block ul li:nth-last-child(3),
.order_data_wrapper .order_data_head ul li:nth-last-child(2),
.order_data_wrapper .order_head_record_block ul li:nth-last-child(2) {
  width: 105px;
  min-width: 105px;
}

.order_data_wrapper .order_data_head ul li:last-child,
.order_data_wrapper .order_head_record_block ul li:last-child {
  width: 145px;
  min-width: 145px;
}

.order_data_listing_block .accordion .accordion-item {
  margin: 0 0 18px 0;
  border: 0;
}

.order_head_record_block ul li,
.order_head_record_block ul li.list-group-item {
  font-weight: normal;
  font-size: 18px;
  color: var(--bs-dark);
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
}

.order_head_record_block ul li:first-child {}

.order_data_wrapper .order_head_record_block ul li:last-child .btn-link {
  padding: 10px 0;
}

.order_data_wrapper .order_head_record_block ul li .link-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: var(--bs-secondary);
  display: block;
}

.order_data_wrapper .accordion-collapse {
  position: relative;
  overflow-x: auto;
  width: 100%;
}

.order_data_listing_block .accordion-body {
  padding: 0;
}

.order_details_block {
  padding: 30px 40px 15px 60px;
  border-top: 1px solid var(--bs-gray-300);
}

.order_details_block ul li,
.order_details_block .order_user_info {
  font-weight: 500;
}

.order_details_block .order_user_dietary_block {
  max-width: 320px;
}

.order_details_block .order_user_info {
  color: var(--bs-secondary);
}

/* !!!! ADMIN CPD POINS PAGE !!!!*/
.admin_right_content_block .information_content {
  max-width: 615px;
}

.admin_right_content_block .information_block {
  margin-bottom: 45px;
}

/* !!!! ADMIN PROFILE PAGE !!!!*/
.profile_card {
  position: relative;
  padding: 30px 20px;
  margin: 0 0 18px;
}

.profile_card_content {
  max-width: 610px;
}

.profile_card .display-4 {
  margin: 0 0 20px;
}

.profile_card .form-control {
  margin: 0;
}

.profile_shipping_address_details .form-check .form-check-label {
  font-size: 16px;
  color: var(--bs-dark);
  text-transform: uppercase;
}

.profile_cta {
  margin: 45px 0 0;
  column-gap: 30px;
  row-gap: 10px;
}

.profile_cta .btn {
  max-width: 150px;
  width: 100%;
}

.profile_cta .btn.btn-cancle {
  background-color: #f0f0f0;
  border: 0;
  color: var(--bs-primary);
  box-shadow: inset -2px -2px #e0e0e0;
  padding: 9px 9px 7px;
}

.profile_cta .btn.btn-cancle:hover {
  color: var(--bs-dark);
}

/* !!!! ADMIN LIBRARY PAGE !!!!*/
.admin_library_card ul li {
  line-height: 17px;
}

.admin_library_card {
  min-height: 275px;
  margin: 0 0 20px;
}

.admin_library_card .card-body {
  position: relative;
  padding: 26px;
}

.admin_library_card .card-title {
  color: var(--bs-dark);
  margin: 0 0 20px;
}

.admin_library_card .library_cate {
  margin-bottom: 30px;
}

@media (min-width:768px) {
  .admin_library_card .library_cate {
    position: absolute;
    top: 36px;
    left: 16px;
    width: 80px;
    height: 80px;
    margin-bottom: 0;
    /* padding: 12px;
      background-color: var(--bs-secondary); */
  }
}

.admin_library_card .library_cate h6 {
  color: var(--bs-white);
  margin: 0;
  line-height: 20px;
  text-align: left;
}

.admin_library_card .library_cate small {
  font-family: 'PT Serif', serif;
  font-size: 14px;
  color: var(--bs-white);
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.library_video_block {
  margin: 26px 0 0;
}

.admin_library_card .library_video_block li {
  position: relative;
  padding: 0 0 0 80px;
  min-height: 30px;
  margin: 0 0 23px;
  display: flex;
  align-items: center;
}

.library_video_block .library_icons {
  position: absolute;
  left: 0;
  top: 0;
}

.library_video_block .library_icons *:not(:last-child) {
  margin-right: 6px;
}

/* !!!! ADMIN CPD POINTS PAGE !!!!*/
.cpd_point_data_wrapper .cpd_point_data_head {
  margin: 0 0 18px;
  overflow-x: auto;
  width: 100%;
}

.cpd_point_data_wrapper .cpd_point_data_head ul li {
  font-weight: 500;
  font-size: 18px;
  color: var(--bs-secondary);
  padding: 19px 10px;
}

.cpd_point_data_wrapper .cpd_point_details_card {
  padding: 23px 0;
  margin: 0 0 18px;
  overflow-x: auto;
  width: 100%;
  font-size: 14px;
}

.cpd_point_data_wrapper .cpd_point_data_head ul li:first-child {
  padding: 19px 26px;
}

.cpd_point_details_card .cpd_point_property_block {
  padding: 0 26px;
}

.cpd_point_details_card .cpd_point_details_card p,
.cpd_point_details_card .cpd_point_details_card li {
  line-height: 17px;
}

.cpd_point_details_card .cpd_point_property_block .cpd_info {
  color: var(--bs-dark);
}

.cpd_point_details_card .cpd_point_property_block .attendee {
  margin: 0 0 9px;
}

.cpd_point_details_card .cpd_topics ul {
  flex-wrap: wrap;
}

.cpd_point_details_card .cpd_topics ul li {
  flex: 0 0 auto;
  width: 50%;
  font-size: 14px;
  line-height: 18px;
  margin: 7px 0;
}

.cpd_point_details_card .cpd_topics ul li span {
  margin: 0 9px 0 0;
}

.cpd_point_data_wrapper .cpd_point_details_card ul li.cpd_point_total {
  font-weight: 500;
  text-align: center;
}

.cpd_point_data_wrapper .cpd_point_details_card ul li.cpd_point_date,
.cpd_point_data_wrapper .cpd_point_details_card ul li.cpd_point_type {
  padding: 0 10px;
}

.cpd_point_data_wrapper .cpd_point_data_head ul li:first-child,
.cpd_point_details_card .cpd_point_property_block {
  width: 360px;
  min-width: 360px;
}

.cpd_point_data_wrapper .cpd_point_data_head ul li:nth-child(2),
.cpd_point_details_card .cpd_topics {
  width: 140px;
  min-width: 140px;
}

.cpd_point_data_wrapper .cpd_point_data_head ul li:nth-last-child(3),
.cpd_point_data_wrapper .cpd_point_details_card ul li.cpd_point_type {
  width: 115px;
  min-width: 115px;
}

.cpd_point_data_wrapper .cpd_point_data_head ul li:nth-last-child(2),
.cpd_point_data_wrapper .cpd_point_details_card ul li.cpd_point_date {
  width: 100px;
  min-width: 100px;
}

.cpd_point_data_wrapper .cpd_point_data_head ul li:last-child,
.cpd_point_data_wrapper .cpd_point_details_card ul li.cpd_point_total {
  width: 120px;
  min-width: 120px;
}

/* ====================================================== */
/*     |!|!|!|!|  ATTENDEES PAGE STYLE CSS  |!|!|!|!|     */
/* ====================================================== */
.attendees_page {
  position: relative;
  padding: 50px 0;
}

.attendees_content .display-5 {
  margin: 0 0 55px;
}

.attendees_card {
  max-width: 700px;
  margin: 0 0 18px 0;
}

.attendees_card .card {
  padding-right: 40px;
}

.attendees_card .accordion-button {
  left: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  padding: 10px;
  background: var(--bs-white);
  box-shadow: none;
}

.attendees_card .card-body {
  position: relative;
  padding: 26px 0 26px 26px;
}

.attendees_card .card-body .card-title {
  position: relative;
  padding: 0;
  min-height: 100%;
}

.attendees_card .card-body .card-title>h3 {
  margin: 0;
}

.attendees_details_right {
  position: relative;
  right: 0;
  top: auto;
  transform: none;
}

.attendees_details_right h3 {
  border-radius: 9px;
  background-color: var(--bs-gray-light);
  border: 1px solid var(--bs-gray-200);
  margin: 0;
  padding: 13px 15px;
  text-align: center;
}

.attendees_details_right h3:not(:last-child) {
  margin-right: 8px;
}

/* .attendees_details_right h3:last-child {
    padding: 13px 20px;
  } */
.attendees_details_block {
  margin: 15px 0 0 0;
}

.attendees_details_block+.attendees_details_block {
  margin-top: 30px;
}

.attendees_details_users {
  margin: 22px 0 22px;
}

.attendees_form_details .form-group {
  margin: 0 0 11px;
}

.attendees_form_details .form-group.gx-2 {
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}

.attendees_details_users .form-check {
  margin: 0 0 0 23px;
}

.attendees_details_users small {
  font-size: 12px;
  line-height: 14px;
  color: var(--bs-secondary);
  margin: 0 0 0 7px;
}

.attendees_form_details .dietary_req {
  margin: 24px 0 0;
}

.attendees_form_details .dietary_req .form-check+.form-check {
  margin-left: 15px;
}

.dietary_req>label {
  color: var(--bs-gray);
  margin: 0 8px 0 0;
}

.attendees_cta {
  margin: 43px 0 0;
}

.attendees_cta .btn {
  min-width: 225px;
}

.attendees_order_right_sidebar {
  border-radius: 4px;
  border: 1px solid var(--bs-gray-200);
}

.attendees_order_right_sidebar>h3 {
  margin: 0;
  padding: 28px 20px;
}

.attendees_order_listing {
  position: relative;
  overflow-x: auto;
  max-height: 400px;
}

.attendees_order_items {
  position: relative;
  padding: 23px 20px 23px 20px;
  border-top: 1px solid var(--bs-gray-100);
}

.attendees_order_items .display-6 {
  margin: 0 0 12px;
}

.attendees_order_items small {
  line-height: 20px;
  color: var(--bs-gray-dark);
  display: block;
}

.attendees_order_items .attendees_order_price {
  position: relative;
  right: auto;
  top: auto;
}

.attendees_order_item_total {
  position: relative;
  padding: 23px 20px 23px 20px;
  border-top: 1px solid var(--bs-gray-100);
}

.coupon_code_block {
  padding: 0 20px 29px;
}

.attendees_order_total_gst {
  border-radius: 0px 0px 4px 4px;
  background-color: var(--bs-primary);
  padding: 17px 20px;
}

.attendees_order_total_gst h3 {
  font-weight: 500;
  line-height: 26px;
}

.attendees_order_total_gst h3 small {
  margin-left: 6px;
}

.need_help_block {
  border-radius: 4px;
  background-color: var(--bs-white);
  border: 1px solid var(--bs-gray-100);
  margin: 52px 0 0;
  padding: 12px 15px 20px 24px;
}

.need_help_block .display-6 {
  margin: 0 0 14px;
}

.need_help_block h3 {
  color: var(--bs-gray-dark);
  font-weight: 500;
  margin: 0;
  display: flex;
  align-items: center;
}

.need_help_block h3 a {
  color: var(--bs-gray-dark);
}

.need_help_block h3 svg {
  margin: 0 8px 0 0;
}

.need_help_block .btn-link {
  font-weight: 500;
  font-size: 14px;
}

.mygrey-text {
  padding: 0 0 0 15px;
  color: var(--bs-gray-dark);
}

.mygrey-text a,
.mygrey-text a:not([href]):not([class]) {
  color: var(--bs-primary);
}

/* ================================================= */
/*     |!|!|!|!|  CART PAGE STYLE CSS  |!|!|!|!|     */
/* ================================================= */
.cart_page {
  position: relative;
  padding: 50px 0;
}

.product_cate {
  width: 92px;
  height: 92px;
  padding: 12px 0 12px 12px;
  border-radius: 4px;
  background-color: var(--bs-secondary);
}

.product_cate h6 {
  color: var(--bs-white);
  margin: 0;
  line-height: 20px;
  text-align: left;
}

.product_cate small {
  font-family: 'PT Serif', serif;
  font-size: 14px;
  color: var(--bs-white);
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

table.card_product_listing,
table.card_product_listing tr>* {
  border: 0;
}

table.card_product_listing.table thead tr th {
  padding: 20px 15px;
  font-weight: 500;
  font-size: 18px;
  color: var(--bs-secondary);
}

.card_product_listing_inner tbody tr:not(:last-child) {
  border-bottom: 1px solid var(--bs-gray-100);
}

table.card_product_listing.table tbody tr td {
  padding: 30px 15px;
  vertical-align: middle;
}

.card_product_listing_head tr th:nth-child(1),
.card_product_listing tbody .card_product_listing_inner tr td:nth-child(1) {
  min-width: 120px;
  width: 120px;
}

.card_product_listing_head tr th:nth-child(3),
.card_product_listing tbody tr td:nth-child(3),
.card_product_listing_head tr th:nth-child(4),
.card_product_listing tbody tr td:nth-child(4),
.card_product_listing_head tr th:nth-child(5),
.card_product_listing tbody tr td:nth-child(5) {
  min-width: 120px;
  width: 120px;
}

.card_product_listing_head tr th:last-child,
.card_product_listing tbody .card_product_listing_inner tr td:last-child {
  min-width: 65px;
  width: 65px;
}

/* ====================================================== */
/*     |!|!|!|!|  THANK YOU PAGE STYLE CSS  |!|!|!|!|     */
/* ====================================================== */
.thank_you_page {
  position: relative;
  padding: 50px 0;
}

.thank_you_card .card-body {
  padding: 26px;
}

.thankyou_program_card {
  padding: 26px 26px 0;
}

.thankyou_program_card .card-title {
  color: var(--bs-dark);
}

.thankyou_program_card .card-body {
  position: relative;
  padding: 0 0 10px;
}

.thankyou_program_card:not(:last-child) .card-body {
  border-bottom: 2px solid var(--bs-gray-100);
}

.thankyou_program_card .program_cate {
  top: 0;
  left: 0;
}

.highlight_text {
  max-width: 320px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin: 20px 0 23px;
  color: var(--bs-primary);
}

.subtotal_block {
  position: relative;
  padding: 20px 26px;
  background: var(--bs-gray-200);
}

.total_block {
  padding: 35px 26px 40px;
}

.program_card.card {
  /* min-height: 310px; */
  border-radius: 3px;
  border: 1px solid var(--bs-primary);
  box-shadow: inset -4px -4px 0 var(--bs-light);
}

.expand_details_block {
  height: 0;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  padding: 0;
  transition: all 0.3s ease-in-out;
  border-top: 1px solid var(--bs-primary);
  box-shadow: inset 4px 4px 0 var(--bs-light);
}

.expand_details_block.active {
  opacity: 1;
  visibility: visible;
  height: auto;
  padding: 20px;
}

.program_card .btn-expand-link,
.filter-expan-link.btn-expand-link {
  font-size: 14px;
  font-weight: 400;
  min-width: 110px;
  text-decoration: underline;
  color: var(--bs-primary);
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}

.filter-expan-link.btn-expand-link {
  float: right;
  margin: 0;
  min-width: auto;
  margin-top: 5px;
}

.program_card .btn-expand-link svg,
.filter-expan-link.btn-expand-link svg {
  margin-left: 5px;
  width: 15px;
}

.attendy-paragraph {
  max-width: 700px;
  margin-bottom: 10px;
}

.alert {
  padding: 5px;
  font-weight: 500;
}

.grid-product-image {
  background-color: transparent;
}

.selected-tag span {
  margin-right: 10px;
}

.selected-tag {
  margin-bottom: 10px;
}

.privacy_policy_modal img {
  float: left;
  width: 30%;
  padding-right: 20px;
  padding-bottom: 20px;
}

.quote_author_card .quote_description p:empty,
.quote_author_card .quote_description p br:empty,
.privacy_policy_modal .about_page_wrapper p:empty,
.privacy_policy_modal .about_page_wrapper p br:empty {
  display: none;
}

.no-need-atendee button {
  display: none;
}

/*==== 07/05/22====*/
.top_bar_wrapper {
  position: relative;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 9px 0 7px;
}

.top_bar_wrapper p:last-child {
  margin-bottom: 0;
}

.top_bar_wrapper a {
  color: var(--bs-secondary);
  text-decoration: underline !important;
}

.top_bar_close {
  position: absolute;
  top: 11px;
  right: 13px;
  line-height: 0;
  cursor: pointer;
}

/*=== Location Modal ===*/
.location_modal .modal-content {
  border: 0;
  border-radius: 4px;
  --bs-bg-opacity: 0.95;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity));
}

.location_modal .modal-header {
  padding: 51px 0 0;
  max-width: 506px;
  margin: 0 auto 33px;
}

.location_modal .modal-header h3 {
  margin: 35px 0 0;
  line-height: 36px;
  color: var(--bs-primary);
}

.location_modal .modal-body {
  padding: 0 15px 70px;
  max-width: 390px;
  margin: 0 auto;
}

.location_modal_content {
  margin: 0 0 40px;
}

.location_modal_content p {
  font-size: 14px;
  line-height: 22px;
  color: var(--bs-primary);
}

.location_modal_content p svg {
  margin-right: 5px;
}

.location_option .form-check {
  position: relative;
  margin: 0 0 20px;
  padding: 0;
  min-height: unset;
}

.location_option .form-check .form-check-label {
  height: 49px;
  border-radius: 3px;
  background: transparent;
  border: 2px solid var(--bs-primary);
  box-shadow: inset -2px -2px 0 var(--bs-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 22px;
  color: var(--bs-primary);
  margin: 0 auto;
}

.location_option .form-check-input[type=radio] {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  display: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.location_option .form-check-input:checked[type=radio]+.form-check-label {
  background: var(--bs-primary);
  color: var(--bs-white);
  border-color: transparent;
}

.location_option .btn-secondary {
  margin: 10px 0 0;
  font-size: 22px;
  line-height: 26px;
  font-weight: 400;
  text-transform: none;
  min-width: 165px;
  padding: 9px 9px 10px;
}

/*-=-=-= Banner -=-=-*/
.hero_banner_wrapper {
  position: relative;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 506px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 50px 0;
}

.banner_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.hero_banner_logo_icon {
  position: absolute;
  top: 20px;
  right: -400px;
}

.hero_banner_content {
  position: relative;
}

.hero_banner_wrapper .btn {
  font-weight: normal;
  font-size: 22px;
  min-width: 180px;
  padding: 10px 9px 8.5px;
  margin-top: 20px;
  text-transform: inherit;
}

@media (max-width: 575px) {
  .hero_banner_content {
    text-align: center;
  }

  .hero_banner_logo_icon {
    max-width: 300px;
    right: auto;
    left: -130px;
    bottom: 0;
    top: auto;
  }

  .hero_banner_wrapper h2 {
    font-size: 24px;
    line-height: 30px;
  }
}

/*-=-= Advantage Section -=-=*/
.advantage_inner {
  position: relative;
  background-color: var(--bs-dark);
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--bs-white);
  padding: 55px 40px 85px;
  z-index: 1;
  border-radius: 4px;
  margin-top: -43px;
}

.advantage_inner .title_wrap {
  margin-bottom: 30px;
}

.advantage_block_wrap {
  max-width: 1045px;
  margin: 0 auto;
}

.advantage_block_icon {
  margin-right: 18px;
  flex-grow: 1;
  flex-shrink: 0;
}

.advantage_block_content {
  color: #FFDF9B;
}

.advantage_block_content h5,
.advantage_block_content .h5 {
  font-weight: 300;
}

@media (max-width: 1199px) {
  .advantage_block_icon img {
    height: 55px;
  }

  .advantage_block_content h5 {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .advantage_block_wrap .row {
    row-gap: 30px;
  }
}

@media (max-width: 767px) {
  .advantage_block_icon {
    max-width: 120px;
  }
}

@media (max-width: 575px) {
  .advantage_inner {
    padding: 55px 30px 85px;
  }

  .advantage_block {
    flex-direction: column;
    text-align: center;
  }

  .advantage_block_icon {
    margin-bottom: 15px;
  }
}

/*-=-= Search -=-=*/
.search_wrapper {
  position: relative;
  z-index: 2;
  margin: -40px auto 0;
  padding-bottom: 50px;
  /* overflow: hidden; */
}

.search_inner {
  position: relative;
  z-index: 2;
  max-width: 100%;
  margin: -40px auto 0;
}

.search_inner .input-group {
  border: 1px solid var(--bs-primary);
  --bs-shadow-opacity: 0.15;
  box-shadow: inset -4px -4px rgba(var(--bs-primary-rgb), var(--bs-shadow-opacity));
  border-radius: 3px;
  padding: 10px 0;
  background-color: #fff;
}

.search_inner .input-group .form-control {
  box-shadow: none;
  border: 0;
  font-size: 18px;
  background-color: transparent;
  padding: 18px 12px 18px 25px;
  color: var(--bs-primary);
}

.search_inner .input-group .form-control::placeholder {
  color: var(--bs-primary);
}

.search_inner .input-group .btn {
  min-width: 85px;
  border-left: 1px solid rgba(var(--bs-primary-rgb), 0.25);
  padding: 0;
  margin: 0;
}

.fillter_wrapper {
  padding: 21px 25px;
  background-color: var(--bs-light);
}

.filter_list_wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding-right: 40px;
  height: 96px;
}

.filter_list_more {
  position: absolute;
  border: 1px solid rgba(var(--bs-primary-rgb), .1);
  font-size: var(--bs-body-font-size);
  line-height: 18px;
  font-weight: 400;
  padding: 6px 4px 4px;
  border-radius: 4px;
  color: var(--bs-primary);
  background-color: var(--bs-light);
  transition: var(--bs-base-transition);
  min-width: 36px;
  height: 38px;
  cursor: pointer;
  text-align: center;
  margin: 0;
  bottom: 10px;
  right: 0;
  z-index: 2;
}

.filter_list_wrapper .filter_title {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  text-decoration: underline;
  margin-right: 16px;
}

.filter_list .form-check {
  position: relative;
  margin: 0 10px 10px 0;
}

.filter_list .form-check-label {
  position: relative;
  border: 1px solid rgba(var(--bs-primary-rgb), 0.1);
  font-weight: 400;
  padding: 6px 17px 4px;
  border-radius: 4px;
  text-align: center;
  color: var(--bs-primary);
  background-color: transparent;
  transition: var(--bs-base-transition);
}

.filter_list .form-check .form-check-input[type="checkbox"] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  ;
}

.filter_list .form-check .form-check-input[type="checkbox"]:checked+.form-check-label,
.filter_list .form-check .form-check-input[type="checkbox"][checked]+.form-check-label,
.filter_list .form-check .active.form-check-label {
  background-color: var(--bs-primary);
  color: var(--bs-white);
  box-shadow: inset 0px 0px 4px rgba(var(--bs-dark-rgb), 1);
}

.filter_list_more:hover {
  background-color: var(--bs-primary);
  color: var(--bs-white);
  box-shadow: inset 0px 0px 4px rgba(var(--bs-dark-rgb), 1);
}

@media (max-width: 575px) {
  .search_wrapper {
    margin: 0 auto 0;
  }

  .search_inner {
    background-color: var(--bs-light);
    margin: 0;
  }

  .filter_search_block {
    max-width: 90%;
    top: -40px;
    margin: 0 auto;
  }

  .filter_list_wrapper {
    height: 329px;
    margin-top: -40px;
  }

  .filter_list_wrapper .filter_title {
    margin-bottom: 10px;
  }

  .areas_wrapper .filter_list_wrapper {
    margin-top: 0;
    height: 190px;
  }
}

/*-=- =featured Section -=-=*/
.featured_wrapper {
  background-size: 48px;
  background-color: var(--bs-light);
  padding: 77px 0;
}

.featured_wrapper .title_wrap {
  margin-bottom: 33px;
}

.featured_wrapper .swiper-container {
  padding-bottom: 60px;
}

.featured_wrapper .swiper-wrapper .swiper-slide {
  height: auto;
  margin-bottom: 24px;
}

.featured_wrapper .swiper-pagination {
  bottom: 0;
}

/*-=-=- product grid -=-=*/
.product_grid_block {
  position: relative;
  background-color: var(--bs-white);
  padding: 25px;
  border: 1px solid var(--bs-light);
  border-radius: 4px;
  box-shadow: 6px 6px 0px #E5EDF1;
  -webkit-box-shadow: 6px 6px 0px #E5EDF1;
  /* margin-bottom: 24px; */
  height: 100%;
  display: flex;
  flex-direction: column;
}

.product_grid_block_top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 23px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.product_grid_block_title {
  max-width: 225px;
  width: 100%;
  padding-right: 10px;
}

.product_grid_block_title h2 {
  font-weight: bold;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.product_grid_block_title p {
  color: rgba(var(--bs-primary-rgb), 0.45);
}

.product_grid_block_title p:last-child {
  margin-bottom: 0;
}

.product_grid_block_icons {
  text-align: center;
  max-width: 45px;
}

.product_grid_block_icons img {
  display: block;
  margin: 0 auto 13px;
}

.product_grid_block_icons img:last-child {
  margin: 0 auto;
}

.product_grid_block_tags {
  margin-bottom: 12px;
}

.product_grid_block_tags img {
  margin: 0 4px 4px 0;
}

.product_grid_block_titmings {
  margin-bottom: 15px;
}

.product_grid_block_titmings .inside_block {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
}

.product_grid_block_titmings .inside_block .icon {
  margin-right: 10px;
  min-width: 20px;
  flex-shrink: 0;
  line-height: normal;
}

.product_grid_block_titmings .inside_block .content {
  font-size: 16px;
  font-weight: 400;
}

.product_grid_block_price * {
  font-weight: bold;
  display: inline-block;
  margin-right: 8px;
  letter-spacing: -0.754px;
}

.product_grid_block_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.product_grid_block_btns .btn {
  font-size: 14px;
  min-width: unset;
}

.areas_wrapper {
  position: relative;
  padding: 70px 0 97px;
}

.areas_wrapper .title_wrap {
  margin-bottom: 44px;
}

.areas_wrapper .fillter_wrapper {
  padding: 0 0 28px;
  background-color: transparent;
}

.areas_wrapper .row [class*="col-"] {
  margin-bottom: 24px;
}

.areas_wrapper .btn_wrap {
  margin-top: 55px;
}

.areas_wrapper .btn_wrap .text {
  margin-bottom: 12px;
  font-size: 14px;
}

.cta_wrapper {
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px 0;
  min-height: 810px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.cta_logo_icon {
  position: absolute;
  right: -305px;
  top: 162px;
}

.cta_content {
  position: relative;
  z-index: 1;
}

.cta_content .title .h1 {
  margin-bottom: 5px;
}

.cta_content .title {
  max-width: 472px;
  margin-bottom: 27px;
}

.cta_form .cstype_wrap {
  margin-top: 16px;
}

.cta_form .btn {
  font-weight: normal;
  text-transform: inherit;
  margin-top: 34px;
}

.cta_form .more_options {
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 575px) {
  .cta_wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0 0 0 / 50%);
  }

  .cstype_wrap .form-check .form-check-label,
  .cta_content .title .h1,
  .cta_form .more_options {
    color: var(--bs-white);
  }

  .cta_content .title h2.fw-light {
    font-size: 24px;
    color: var(--bs-white);
    max-width: 250px;
    line-height: 30px;
  }

  .cta_logo_icon {
    width: 350px;
    right: -150px;
  }
}

/* ======================================================== */
/*     |!|!|!|!|  RESPONSIVE MEDIA STYLE CSS  |!|!|!|!|     */
/* ======================================================== */
@media (min-width: 576px) {

  /* !!! Header !!! */
  .cart_products_listing li,
  .cart_products_listing li.list-group-item {
    padding: 15px 110px 15px 0;
  }

  .cart_products_listing li .cart_price {
    position: absolute;
    right: 40px;
    top: 20px;
  }

  /* !!!!  Programs Page Style  !!!! */
  .program_card .card-body ul li.cpd_points,
  .program_card .card-body ul li.cpd_location {
    min-width: 85px;
  }

  /* .program_card .card-body ul li.cpd_topics {
    min-width: 170px;
  } */

  /* .program_card .address {
    padding: 0 0 0 26px;
    color: var(--bs-gray-dark);
  } */

  /* .pagination .page-link {
    padding: 0 10px;
  } */

  /* !!!!  Programs Single Page Style  !!!! */
  .courses_card .courses_card_content {
    padding: 30px 25px;
  }

  .related_courses_wrapper .swiper-container .swiper-button-prev {
    left: 38%;
  }

  .related_courses_wrapper .swiper-container .swiper-button-next {
    right: 38%;
  }

  /* !!! Admin Profile PAge !!! */
  .profile_card {
    padding: 45px;
    margin: 0 0 18px;
  }

  .profile_card .display-4 {
    margin: 0 0 43px;
  }
}

@media (min-width: 768px) {

  /* !!! Header !!! */
  .cart_products_listing li,
  .cart_products_listing li.list-group-item {
    padding: 15px 140px 15px 80px;
  }

  /* !!!!  Programs Page Style  !!!! */
  /* .program_card .card-body {
    padding: 100px 15px 20px 0;
  } */

  /* !!!!  Programs Single Page Style  !!!! */
  .single_program_page {
    padding: 95px 0;
  }

  .cpd_single_programs_content hr {
    margin: 60px 0;
  }

  .publication_details_wrapper .publication_details_card {
    padding: 30px 36px 41px;
  }

  .cpd_single_programs_content .accordion .accordion-item .publication_details_wrapper .publication_details_card {
    padding: 30px 25px;
  }

  .related_courses_wrapper .swiper-container .swiper-button-prev {
    left: 41%;
  }

  .related_courses_wrapper .swiper-container .swiper-button-next {
    right: 41%;
  }

  /* !!! Admin Pages Common Style !!! */
  .admin_page {
    padding: 88px 0;
  }

  /* !!! Admin Library page !!! */
  .admin_library_card .card-body {
    padding: 36px 30px 20px 125px;
  }

  .admin_library_card .library_video_block li {
    padding: 0 0 0 98px;
  }

  .library_video_block .library_icons *:not(:last-child) {
    margin-right: 12px;
  }

  /* !!! Admin Events page !!! */
  .admin_event_card .card-body {
    padding: 26px 225px 36px 26px;
  }

  /* !!!! Attendees Page !!!! */
  .attendees_page {
    padding: 86px 0;
  }

  .attendees_card .card-body {
    padding: 15px 0 35px 120px;
  }

  /* !!!! Cart Page style !!!! */
  .cart_page {
    padding: 80px 0;
  }

  /* !!! Thankyou Page !!! */
  .thank_you_page {
    padding: 100px 0;
  }

  .thank_you_card .card-body {
    padding: 50px 35px 50px 45px;
  }

  .thankyou_program_card .card-body {
    padding: 0 0 50px 100px;
  }

  .thankyou_program_card {
    padding: 35px 35px 20px 35px;
  }

  .subtotal_block {
    padding: 20px 35px;
  }

  .total_block {
    padding: 35px 35px 40px 35px;
  }

  .search_inner {
    max-width: 640px;
  }
}

@media (min-width: 992px) {

  /* !!!! HEADER !!!! */
  .navbar-expand-lg .navbar-nav {
    align-items: center;
  }

  nav.navbar .navbar-nav>li:first-of-type {
    padding-left: 0;
  }

  nav.navbar .navbar-nav>li:last-child {
    padding-right: 0;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
  }

  /*!!! Banner !!!*/
  .hero_banner_content {
    max-width: 448px;
  }

  /* !!!!  Programs Page Style  !!!! */
  /* .program_card .card-body {
    padding: 15px 170px 25px 0;
  } */

  .program_cate2 {
    top: 35px;
    left: 15px;
    width: 90px;
    height: 90px;
    padding: 10px 0 10px 10px;
  }

  .program_cate2 img {
    max-height: 80;
  }

  /* .program_card .program_right {
    position: absolute;
    right: 15px;
    top: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  } */

  /* !!!!  Programs Single Page Style  !!!! */
  .related_courses_wrapper .swiper-container .swiper-button-prev {
    left: 44%;
  }

  .related_courses_wrapper .swiper-container .swiper-button-next {
    right: 44%;
  }

  /* !!! Admin Orders Page !!! */
  .order_details_block {
    padding: 30px 40px 15px 60px;
  }

  /* !!!! Attendees Page !!!! */
  .attendees_card .card-body {
    padding: 35px 0 35px 120px;
  }

  .attendees_card .card-body .card-title {
    padding: 0 180px 0 0;
    min-height: 92px;
    margin: 0;
  }

  .attendees_details_right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  .attendees_order_items {
    padding: 23px 100px 23px 20px;
  }

  .attendees_order_items .attendees_order_price {
    position: absolute;
    right: 20px;
    top: 23px;
  }

  /* !!!! Thankyou Pagee !!!! */
  .thankyou_program_card .program_cate {
    left: 10px;
  }

  .thankyou_program_card .card-body {
    padding: 0 0 50px 120px;
  }

  .search_inner {
    max-width: 850px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }

  /* !!!!  HEADER  !!!! */
  nav.navbar .navbar-nav li {
    padding: 0 8px;
  }

  /* !!!!  Programs Page Style  !!!! */
  /* .program_card .card-body {
    padding: 15px 370px 25px 0;
  } */

  .program_cate2 {
    left: 26px;
    width: 92px;
    height: 92px;
    padding: 12px 0 12px 12px;
  }

  /* .program_card .program_right {
    right: 21px;
    max-width: 317px;
} */

  /* .program_card .program_right .btn-primary {
    min-width: 160px;
  } */

  /* !!!!  Programs Single Page Style  !!!! */
  .related_courses_wrapper .swiper-container .swiper-button-prev {
    left: 45%;
  }

  .related_courses_wrapper .swiper-container .swiper-button-next {
    right: 45%;
  }

  /* !!! Admin Library page !!! */
  .admin_library_card .card-body {
    padding: 36px 120px 20px 130px;
  }

  .admin_library_card .library_cate {
    left: 26px;
    /* width: 80px;
      height: 80px; */
  }

  /* !!! Admin Events Page !!! */
  .admin_event_card .card-body {
    padding: 26px 360px 36px 26px;
  }

  /* !!! Admin Orders Page !!! */
  .order_details_block {
    padding: 30px 130px 15px 60px;
  }

  /* !!!! Attendees Page !!!! */
  .attendees_card .card-body {
    padding: 35px 0 35px 151px;
  }

  .mygrey-text {
    padding: 0 0 0 5px;
  }

  /* !!! Thankyou page !!! */
  .thankyou_program_card .card-body {
    padding: 0 0 50px 150px;
  }

  .thankyou_program_card .program_cate {
    left: 15px;
  }

  .search_inner {
    max-width: 1040px;
  }

  .filter_list .form-check-label:hover {
    background-color: var(--bs-primary);
    color: var(--bs-white);
    box-shadow: inset 0px 0px 4px rgba(var(--bs-dark-rgb), 1);
  }
}

@media (min-width: 1470px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1448px;
  }

  nav.navbar .navbar-nav li {
    padding: 0 12px;
  }

  .navbar-nav .nav-link {
    font-size: 16px;
  }

  .home_link {
    font-size: 16px;
    padding: 0 34px 0 0;
    margin-right: 32px;
  }

  .footer h6,
  .footer_navbar a,
  .footer_bottom ul li a {
    font-size: 18px;
  }

  .search_inner {
    max-width: 1240px;
  }

  .product_grid_block_btns .btn {
    font-size: 18px;
    min-width: 150px;
  }
}

@media (max-width: 1299px) {

  .navbar-nav .nav-link,
  .home_link {
    font-size: 12px;
    line-height: 18px;
  }
}

@media (max-width: 1199px) {
  .can_we_help_button {
    width: 56px;
    right: -5px;
    bottom: 100px;
  }

  .home_link {
    padding: 0 10px 0 0;
    margin-right: 10px;
  }

  /* !!!!  Programs Page Style  !!!! */
  .program_card .card-body ul li.cpd_topics img {
    margin: 0 2px;
    width: 28px;
  }

  /* !!! Admin All Page's Common Style !!! */
  .admin_right_content_block .search_block {
    margin: 32px 0 0;
  }

  /* !!!! Attendees Page !!!! */
  .attendees_order_total_gst h3 {
    font-size: 18px;
  }

  /* !!! Admin Order page !!! */
  .order_data_listing_block .accordion-body {
    white-space: nowrap;
  }

  .order_data_listing_block .accordion-body ul li:not(:last-child) {
    padding-right: 15px;
  }

  .footer_navbar {
    margin-bottom: 30px;
  }

  .footer_middle {
    padding: 0 0 25px;
  }

  .cpd_points_mode_type_txt {
    grid-row-gap: 6px;
    padding: 20px;
  }
}

@media (max-width: 991px) {
  .home_link {
    border: 0;
    padding: 9px 8px;
  }

  .home_link svg {
    display: none;
  }

  .navbar-nav .nav-link {
    padding: 9px 0;
  }

  .modal-dialog.modal-lg {
    max-width: 700px;
  }

  /* !!!! HEADER !!!! */
  nav.navbar {
    /* overflow: hidden; */
    overflow: inherit;
  }

  .navbar-nav li .nav-link::after {
    display: none;
  }

  .navbar-nav li.active .nav-link {
    color: var(--bs-primary);
  }

  nav .navbar_right {
    padding: 10px 0;
    width: 100%;
    background: var(--bs-secondary);
    position: relative;
  }

  nav .navbar_right::before,
  nav .navbar_right::after {
    content: '';
    position: absolute;
    width: 100%;
    background: var(--bs-secondary);
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  nav .navbar_right::before {
    left: -100%;
  }

  nav .navbar_right::after {
    right: -100%;
  }

  .navbar-light .navbar-toggler {
    color: var(--bs-gray);
    border: 0;
    padding: 0;
    box-shadow: none;
  }

  nav .navbar_right .home_link {
    border-right: 1px solid var(--bs-primary);
    padding: 3px 15px;
  }

  .navbar .navbar_right .btn-secondary {
    min-width: 80px;
    --bs-shadow-opacity: 0.15;
    box-shadow: 0px 0px 2px 2px rgba(var(--bs-dark-rgb), var(--bs-shadow-opacity));
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='16.5' viewBox='0 0 27 16.5'%3E%3Cg transform='translate(-421.5 8582.25)'%3E%3Cline x2='27' transform='translate(421.5 -8581.5)' fill='none' stroke='%23707070' stroke-width='1.5'/%3E%3Cline x2='18' transform='translate(430.5 -8574.5)' fill='none' stroke='%23707070' stroke-width='1.5'/%3E%3Cline x2='27' transform='translate(421.5 -8566.5)' fill='none' stroke='%23707070' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  }

  nav .cart_link {
    margin: 0 10px 0 auto;
  }

  .cart_link svg {
    margin-right: 8px;
  }

  .navbar .navbar_right .btn-secondary-outline {
    background: var(--bs-white);
    border-color: var(--bs-white);
    border-radius: 4px;
    min-width: 85px;
  }

  /* !!!!  Programs Page Style  !!!! */
  .program_card .program_right {
    padding: 15px 15px 25px;
  }

  /*
  .program_card .program_right h3 {
    display: inline-block;
    margin: 0 25px 25px 0;
  } */

  .program_card .program_right .form_check_group {
    display: flex;
    align-items: center;
  }

  /* .program_card .program_right .form_check_group .form-check:not(:last-child) {
    margin: 0 20px 0 0;
  } */
  /*.program_card .btn-link {
    position: absolute;
    right: 26px;
    bottom: 26px;
  } */
  .program_card .btn-expand-link {
    margin-left: 20px;
    margin-right: 0;
  }

  /* !!!!  Programs Single Page Style  !!!! */
  .single_rightside {
    position: relative;
    right: auto;
    top: auto;
    margin: 0 0 20px;
  }

  .quote_author_card .quote_description {
    max-width: 100%;
  }

  .program_card .card-body {
    padding: 0;
  }

  .program_card .program_head {
    display: block;
  }

  .program_card .cpd_points {
    display: block;
    margin-left: 0;
    margin-top: 10px;
  }

  .program_card .program_head,
  .program_card .card-body ul,
  .program_card .price_sale {
    padding: 0 15px;
  }

  .author_groups {
    padding: 15px 0 0 15px;
  }

  /* !!!! wishlist  page !!!! */
  .wishlist_right_block .program_card .card-body .card-text {
    /* padding-left: 0;
    padding-top: 16px;*/
  }

  /* !!! Admin All Page's Common Style !!! */
  .admin_right_content_block .search_block {
    margin: 0;
  }

  /* !!!! Attendees Page !!!! */
  .attendees_card .card-body .card-title>h3 {
    margin: 0 0 20px;
  }

  .mygrey-text {
    padding: 0;
    width: 100%;
  }

  /* !!!! Admin Order page  !!!!*/
  .order_details_block .order_user_dietary_block {
    max-width: 100%;
  }

  /* !!! Cart Page !!! */
  .card_product_listing_head tr th:nth-child(3),
  .card_product_listing tbody tr td:nth-child(3),
  .card_product_listing_head tr th:nth-child(4),
  .card_product_listing tbody tr td:nth-child(4),
  .card_product_listing_head tr th:nth-child(5),
  .card_product_listing tbody tr td:nth-child(5) {
    min-width: 90px;
    width: 90px;
  }
}

@media (max-width: 767px) {

  h1,
  .h1 {
    font-size: 40px;
    line-height: 42px;
  }

  /* !!!! HEADER !!!! */

  /* nav .navbar_right {
    justify-content: center;
    padding: 0;
  } */

  .navbar_right .search_bar {
    margin: 0 11px 0 0;
  }

  .search_bar .form-control {
    width: 215px;
  }

  nav.navbar .navbar-brand .leo_brand {
    max-width: 127px;
    height: auto;
  }

  /**/
  .cta_wrapper {
    background-position: 65% 0;
  }

  /* !!!!  Programs Page Style  !!!! */
  .all_program_page {
    padding: 30px 0 60px;
  }

  .cpd_programs_content h1 {
    text-align: center;
  }

  .cpd_programs_filter .filter_accordion.accordion>.accordion-item>.accordion-header>.accordion-button {
    font-weight: 500;
    font-size: 18px;
    color: var(--bs-primary);
    justify-content: space-between;
  }

  .cpd_programs_filter .accordion.filter_accordion>.accordion-item>.accordion-header>.accordion-button::after {
    display: none;
  }

  .cpd_programs_filter .filter_accordion.accordion>.accordion-item>.accordion-header>.accordion-button[aria-expanded="true"] svg path {
    stroke: var(--bs-primary);
  }

  .cpd_programs_filter .accordion {
    max-width: 100%;
    margin: 0;
  }

  .cpd_programs_filter>.filter_accordion.accordion {
    max-width: 100%;
    margin: 0 0 50px;
    border-radius: 4px;
    background-color: var(--bs-white);
    border: 1px solid var(--bs-gray-200);
  }

  .cpd_programs_filter>.accordion.filter_accordion .accordion .accordion-item {
    border-top: 1px solid var(--bs-gray-200);
    border-bottom: 0;
  }

  .programs_description {
    text-align: center;
    padding: 0 15px;
  }

  .program_card .card-body {
    padding: 0;
  }

  .hero_banner_wrapper {
    display: flex;
    align-items: flex-end;
  }

  /* !!!!  Programs Single Page Style  !!!! */
  .single_program_page .programs_description {
    padding: 0;
  }

  .single_programs_head {
    padding: 0;
    margin: 0 0 30px;
    min-height: 100%;
  }

  .related_courses_wrapper .swiper-container {
    padding: 0 0 80px;
  }

  .courses_card .courses_card_content h4,
  .courses_card .courses_card_content p {
    max-width: 100%;
  }

  .courses_card .courses_card_content ul {
    margin: 10px 0 0;
  }

  .author_testimonial_wrapper {
    background-color: var(--bs-transparent) !important;
    border: 0 !important;
    border-radius: 0 !important;
    padding: 40px 0 0;
  }

  .author_testimonial_wrapper h2 {
    margin: 0;
    background-color: var(--bs-white);
    border: 1px solid var(--bs-gray-200);
    border-bottom: 0;
    border-radius: 4px 4px 0 0;
    padding: 40px 20px 0;
    text-align: center;
  }

  .author_testimonial_wrapper .swiper-container {
    padding: 0 0 75px;
  }

  .author_testimonial_wrapper .quote_author_card {
    background-color: var(--bs-white);
    border: 1px solid var(--bs-gray-200);
    border-top: 0;
    border-radius: 0 0 4px;
    padding: 30px 25px 35px;
  }

  .author_testimonial_wrapper .swiper-container [class*="swiper-button-"] {
    top: auto;
    bottom: 0;
  }

  .author_testimonial_wrapper .swiper-container .swiper-button-prev {
    left: 38%;
    right: auto;
  }

  .author_testimonial_wrapper .swiper-container .swiper-button-next {
    right: 38%;
  }

  .quote_author_card .quote_author_card_content {
    padding: 0;
  }

  .quote_author_block {
    position: relative;
    padding: 25px 0 0 115px;
    min-height: 90px;
    margin: 0 0 32px;
  }

  .program_right_cart_head .program_price {
    font-size: 34px;
  }

  .program_right_cart .list_block small,
  .print_info {
    font-size: 18px;
    line-height: 30px;
  }

  .cpd_points_mode_type_img .program_cate {
    border-radius: 4px;
    margin: 0;
  }

  .cpd_points_mode_type_txt {
    box-shadow: 4px 4px 0px #e5edf1;
    -webkit-box-shadow: 4px 4px 0px #e5edf1;
  }

  /* !!! Admin All Page's Common Style !!! */
  .admin_right_content_block .admin_head {
    flex-direction: column;
    text-align: center;
  }

  .admin_right_content_block .search_block {
    margin: 32px 0 0;
  }

  /* !!!! Admin Events page  !!!!*/
  .event_card_right_block {
    position: relative;
    top: auto;
    right: auto;
    max-width: 100%;
    margin-right: auto;
    margin-top: 30px;
  }

  /* !!!! Attendees Page !!!! */
  .attendees_order_right_sidebar {
    margin: 40px 0 0;
  }

  /* !!! Cart Page !!! */
  table.card_product_listing.table thead tr th {
    padding: 20px 10px;
  }

  table.card_product_listing.table thead tr th:first-child,
  table.card_product_listing.table tbody .card_product_listing_inner tr td:first-child,
  .card_product_listing_head tr th:nth-child(1),
  .card_product_listing tbody .card_product_listing_inner tr td:nth-child(1) {
    min-width: 0;
    width: 0;
    padding: 0;
    display: none;
  }

  .card_product_listing_head tr th:nth-child(5),
  .card_product_listing tbody tr td:nth-child(5) {
    min-width: 70px;
    width: 70px;
  }

  .footer h6,
  .footer_navbar a,
  .footer_bottom ul li a {
    font-size: 14px;
  }

  .footer_bottom p {
    font-size: 12px;
  }

  .footer_top {
    padding: 60px 0 49px;
  }

  .footer_bottom {
    padding: 22px 0;
  }
}

@media (max-width: 575px) {

  /* !!!! HEADER !!!! */
  /* nav .navbar_right {
    position: relative;
  }

  nav .navbar_right::before {
    content: '';
    box-shadow: 0px 0px 4px rgb(0 0 0 / 16%);
    position: absolute;
    left: -11px;
    right: -11px;
    bottom: 0;
    top: 0;
  } */

  /* !!!! FOOTER !!!! */
  .footer_top {
    padding: 60px 0 30px;
  }

  .footer_top .container {
    border-bottom: 1px solid rgba(255, 255, 255, .20);
    padding-bottom: 20px;
  }

  .footer-nav-aside.nav li:not(:last-child) {
    margin-right: 10px;
  }

  .footer_bottom ul {
    justify-content: center;
  }

  .footer_bottom p {
    text-align: center;
  }

  .footer-brand svg {
    width: 135px;
    height: auto;
  }

  .footer .i-call {
    font-size: 22px;
    line-height: 16px;
  }

  /* !!! share_modal popup !!! */
  .modal .modal-body {
    padding: 30px 20px 50px;
  }

  .modal .modal-body .btn-lg {
    padding: 15px 25px;
  }

  .share_modal .modal-body .social_media_list li {
    width: 50px;
    height: 50px;
  }

  /* !!! create account modal popup !!! */
  .create_account_modal .btn {
    min-width: 100%;
  }

  /* !!!!  Programs Page Style  !!!! */
  .cpd_programs_filter .btn-primary {
    max-width: 323px;
  }

  .cpd_programs_content .search_block .date_filed {
    padding: 9px 8px 10px 15px;
  }

  .cpd_programs_filter>.filter_accordion.accordion {
    max-width: 325px;
    margin: 0 auto 50px;
  }

  .cpd_programs_content .search_block {
    max-width: 325px;
    margin: 0 auto;
  }

  .cpd_programs_content .search_block .row {
    margin-right: calc(var(--bs-gutter-x) * -.2);
    margin-left: calc(var(--bs-gutter-x) * -.2);
  }

  .cpd_programs_content .search_block [class*="col-"] {
    padding-right: calc(var(--bs-gutter-x) * .2);
    padding-left: calc(var(--bs-gutter-x) * .2);
  }

  .program_cta {
    flex-wrap: wrap;
    justify-content: center;
  }

  .program_card .btn-expand-link {
    margin: 20px 0 0;
  }

  /* .program_card {
    margin-left: -11px;
    margin-right: -11px;
  } */

  .program_card .card-body ul li {
    min-width: 85px;
  }

  .pagination .page-item:first-child .page-link,
  .pagination .page-item:last-child .page-link {
    font-size: 13px;
  }

  /* .program_card .btn-link {
    bottom: 30px;
  } */

  .program_card .card-body ul li.cpd_topics img {
    width: auto;
  }

  /* !!!!  Programs Single Page Style  !!!! */
  .author_testimonial_wrapper .swiper-container .swiper-button-prev {
    left: 31%;
    right: auto;
  }

  .author_testimonial_wrapper .swiper-container .swiper-button-next {
    right: 31%;
  }

  .single_rightside {
    width: auto;
    max-width: inherit;
  }

  .publication_details_wrapper {
    padding: 30px 0 0 0;
  }

  .publication_details_card ul {
    flex-direction: column;
  }

  .publication_details_card ul li {
    margin: 8px 0;
  }

  /* !!!! Admin Events page  !!!!*/
  .admin_event_card ul {
    flex-direction: column;
  }

  .admin_event_card .card-text ul li.attendee {
    margin: 10px 0 0;
  }

  /* !!!! Admin CPD Points page  !!!! */
  .admin_right_content_block .admin_head .cpd_topics {
    flex-wrap: wrap;
  }

  .admin_right_content_block .admin_head .cpd_topics li .btn {
    margin-left: 0;
  }

  .admin_right_content_block .admin_head .cpd_topics li.export_cta {
    flex: 0 0 100%;
    width: 100%;
    justify-content: center;
    margin: 20px 0 0;
  }

  /* !!! Cart Page !!! */
  table.card_product_listing>thead {
    display: none;
  }

  .card_product_listing .card_product_listing_inner,
  .card_product_listing .card_product_listing_inner tr,
  .card_product_listing .card_product_listing_inner tr td {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  table.card_product_listing.table tbody tr td.product_name {
    width: 100%;
    padding-bottom: 0;
  }
}

@media (max-width: 374px) {

  /* !!!!  HEADER  !!!! */
  .search_bar .form-control {
    width: 174px;
  }

  nav .cart_link {
    margin: 0 20px 0 auto;
  }

  .cart_modal .modal-footer .btn {
    padding: 10px 25px;
  }

  .navbar .navbar_right .btn-secondary {
    min-width: 70px;
  }

  .navbar .navbar_right .btn-secondary-outline {
    min-width: 75px;
  }

  @media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
      .cart_modal .modal-footer .btn {
        padding: 13px 25px 8.5px;
      }
    }
  }

  /* !!!!  Programs Page Style  !!!! */
  /* .program_card .card-body {
    padding: 25px 15px;
  } */

  .program_card .card-body ul li.cpd_topics img {
    width: 25px;
  }

  .program_card .card-body ul li {
    font-size: 12px;
    line-height: 14px;
  }

  .program_card .card-body ul li.cpd_topics img {
    width: 22px;
  }

  .author_card {
    min-height: 37px;
    padding: 0 0 0 40px
  }

  .author_card img {
    width: 35px;
    height: 35px;
  }

  .author_card p {
    font-size: 14px;
    line-height: 18px;
  }

  /* .author_card small {
    font-size: 10px;
    line-height: 11px;
  } */

  .program_card .btn-link svg {
    margin-left: 5px;
  }

  /* !!!!  Programs Single Page Style  !!!! */
  .single_program_page .address_block li {
    padding: 0 0 0 15px;
  }

  .program_right_cart {
    padding: 35px 15px 0;
  }

  .cpd_total_point_block {
    padding: 25px 15px 35px;
  }

  .single_program_page .address_block li.cpd_topics {
    padding: 0 8px;
  }

  .single_program_page .address_block li.cpd_topics img {
    width: 22px;
  }

  .cpd_total_point_block ul .list-group-item {
    font-size: 12px;
    line-height: 28px;
  }

  .cpd_total_point_block ul .list-group-item img {
    width: 20px;
  }

  .publication_details_wrapper .publication_details_card {
    padding: 30px 15px;
  }

  .author_testimonial_wrapper .quote_author_card {
    padding: 30px 15px 35px;
  }

  .courses_card .courses_card_content {
    padding: 30px 15px;
  }

  .courses_card .courses_card_content ul li {
    font-size: 12px;
  }

  .program_cta {
    flex-direction: column;
    row-gap: 20px;
  }

  .program_card .btn-link {
    margin: 0;
  }

  /* !!! Admin Library page !!! */
  .admin_library_card .card-body {
    padding: 26px 15px;
  }

  /* !!! Attendees Page !!! */
  .attendees_details_users .my_self_holder {
    width: 100%;
  }

  .attendees_details_users .form-check {
    margin: 10px 0;
  }
}

.offer-bar {
  position: relative;
  background-color: #ebebeb;
  padding: 7px 0;
}

.offer-bar-inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
}

.offer-bar p {
  color: #707070;
  text-align: center;
  margin: 0;
}

.offer-bar p span {
  color: #e6ab11;
  font-weight: bold;
}

.offer-bar img {
  position: absolute;
  top: 50%;
  right: 10px;
  cursor: pointer;
  transform: translateY(-50%);
}

.offer-two {
  background: #fff;
  border: 1px solid var(--bs-gray-200);
  text-align: center;
  margin-bottom: 35px;
  padding: 10px 30px;
  position: relative;
}

.offer-two * {
  display: inline-block;
  margin: 0;

}

.offer-two p {
  font-weight: 600;
  color: #464646;
  margin-bottom: 0px;
  letter-spacing: 0.5px;
}

.offer-two p span {
  color: var(--bs-primary) !important;
  text-transform: uppercase;
  font-weight: 800;
}

.offer-two img {
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
  transform: translateY(-50%);
}

.label_tag2 {
  display: inline-block;
  border-radius: 10px;
  background-color: var(--bs-primary-light);
  padding: 8px 15px;
  margin-left: 19px !important;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  color: var(--bs-primary);
  margin-bottom: 5px;
}

.wFormContainer .wFormHeader {
  height: auto !important;
}

.supportInfo {
  margin: 0;
  padding: 0;
}

.thankyou li {
  margin-bottom: 10px;
  line-height: 17px;
}

/* ============================================= */
/* !!!!      CPD Programs Page Style        !!!! */
/* ============================================= */
.blue_banner_bar {
  height: 110px;
  margin-top: -70px;
  border-radius: 0 0 6px 6px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.filter_search_block {
  position: relative;
}

.filter_search_block .sc-eCYdqJ {
  height: auto;
}

.filter_search_block .sc-eCYdqJ>.wrapper {
  position: relative;
  border: 0;
  border-radius: 0;
  background-color: transparent;
}

.filter_search_block .sc-eCYdqJ>.wrapper .clear-icon {
  display: none;
}

.filter_search_block .sc-eCYdqJ>.wrapper input {
  border-radius: 3px;
  background: var(--bs-white);
  border: 1px solid var(--bs-primary);
  font-family: var(--bs-font-sans-serif);
  font-weight: 300;
  font-size: 18px;
  width: 100%;
  line-height: 26px;
  text-align: left;
  color: var(--bs-primary);
  padding: 28px 118px 25px 27px;
  box-shadow: inset -4px -4px 0 rgba(0, 78, 109, .15);
}

.filter_search_block .sc-eCYdqJ>.wrapper input::-webkit-input-placeholder {
  color: var(--bs-primary);
}

.filter_search_block .sc-eCYdqJ>.wrapper input::-moz-placeholder {
  color: var(--bs-primary);
}

.filter_search_block .sc-eCYdqJ>.wrapper input:-ms-input-placeholder {
  color: var(--bs-primary);
}

.filter_search_block .sc-eCYdqJ>.wrapper input::-ms-input-placeholder {
  color: var(--bs-primary);
}

.filter_search_block .sc-eCYdqJ>.wrapper input:-moz-placeholder {
  color: var(--bs-primary);
}

.filter_search_block .btn.btn-search {
  position: absolute;
  right: 0;
  top: 10px;
  height: 61px;
  bottom: 10px;
  min-width: 88px;
  min-height: 60px;
  border-left: 1px solid rgba(0, 78, 109, 0.25);
}

.filter_dropdown_list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 15px;
}

.filter_dropdown_list li {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.filter_dropdown_list li .reset_btn {
  padding: 13px 9px 11px;
}

#delivery_mode_select [class$="-placeholder"] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17.5' viewBox='0 0 17 17.5'%3E%3Cg transform='translate(0 0.5)'%3E%3Ccircle cx='3.825' cy='3.825' r='3.825' transform='translate(4.736)' fill='none' stroke='%23004e6d' stroke-miterlimit='10' stroke-width='1'/%3E%3Cpath d='M14.968,6.03A7.652,7.652,0,0,0,0,6.084' transform='translate(1.071 10.2)' fill='none' stroke='%23004e6d' stroke-linecap='round' stroke-miterlimit='10' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 30px;
}

.navbar_right .css-1s2u09g-control,
.navbar_right .css-1pahdxg-control,
.filter_dropdown_list .css-1s2u09g-control,
.filter_dropdown_list .css-1pahdxg-control,
.filter_dropdown_list .form-control,
.sort_order_block .css-1s2u09g-control,
.sort_order_block .css-1pahdxg-control,
.profile_detail_content .css-1s2u09g-control,
.profile_detail_content .css-1pahdxg-control,
.manually_points_modal .css-1s2u09g-control,
.manually_points_modal .css-1pahdxg-control {
  font-family: var(--bs-font-sans-serif);
  width: 100%;
  min-height: 48px;
  border-radius: 3px;
  background-color: var(--bs-white);
  border: 1px solid var(--bs-primary);
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: var(--bs-primary);
  box-shadow: inset -4px -4px 0 rgba(0, 78, 109, .15);
  cursor: pointer;
}

.navbar_right .css-319lph-ValueContainer .css-qc6sy-singleValue,
.filter_dropdown_list .css-319lph-ValueContainer .css-qc6sy-singleValue,
.sort_order_block .css-319lph-ValueContainer .css-qc6sy-singleValue,
.manually_points_modal .css-319lph-ValueContainer .css-qc6sy-singleValue,
.profile_detail_content .css-319lph-ValueContainer .css-qc6sy-singleValue {
  font-weight: 700;
  color: var(--bs-primary);
}

.navbar_right .css-1s2u09g-control:hover,
.navbar_right .css-1pahdxg-control:hover,
.filter_dropdown_list .css-1s2u09g-control:hover,
.filter_dropdown_list .css-1pahdxg-control:hover,
.sort_order_block .css-1s2u09g-control:hover,
.sort_order_block .css-1pahdxg-control:hover,
.profile_detail_content .css-1s2u09g-control:hover,
.profile_detail_content .css-1pahdxg-control:hover,
.manually_points_modal .css-1s2u09g-control:hover,
.manually_points_modal .css-1pahdxg-control:hover {
  border-color: var(--bs-primary);
}

.navbar_right .css-1pahdxg-control .css-14el2xx-placeholder,
.navbar_right .css-1s2u09g-control .css-14el2xx-placeholder,
.filter_dropdown_list .css-1pahdxg-control .css-14el2xx-placeholder,
.filter_dropdown_list .css-1s2u09g-control .css-14el2xx-placeholder,
.sort_order_block .css-1pahdxg-control .css-14el2xx-placeholder,
.sort_order_block .css-1s2u09g-control .css-14el2xx-placeholder,
.profile_detail_content .css-1pahdxg-control .css-14el2xx-placeholder,
.profile_detail_content .css-1s2u09g-control .css-14el2xx-placeholder,
.manually_points_modal .css-1pahdxg-control .css-14el2xx-placeholder,
.manually_points_modal .css-1s2u09g-control .css-14el2xx-placeholder {
  color: var(--bs-primary);
}

.navbar_right .css-1okebmr-indicatorSeparator,
.filter_dropdown_list .css-1okebmr-indicatorSeparator,
.sort_order_block .css-1okebmr-indicatorSeparator,
.profile_detail_content .css-1okebmr-indicatorSeparator,
.manually_points_modal .css-1okebmr-indicatorSeparator {
  display: none;
}

.filter_dropdown_list .css-1okebmr-indicatorSeparator+.css-tlfecz-indicatorContainer,
.filter_dropdown_list .css-1okebmr-indicatorSeparator+.css-1gtu0rj-indicatorContainer,
.sort_order_block .css-1okebmr-indicatorSeparator+.css-tlfecz-indicatorContainer,
.sort_order_block .css-1okebmr-indicatorSeparator+.css-1gtu0rj-indicatorContainer {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='15.652' viewBox='0 0 13 15.652'%3E%3Cg transform='translate(13 0.444) rotate(90)'%3E%3Cpath d='M0,.5H14.444' transform='translate(-0.444 6)' fill='none' stroke='%23004e6d' stroke-miterlimit='10' stroke-width='1'/%3E%3Cpath d='M0,10,5,5,0,0' transform='translate(9.5 1.5)' fill='none' stroke='%23004e6d' stroke-miterlimit='10' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  padding: 10px 15px;
}

.filter_dropdown_list .css-1okebmr-indicatorSeparator+.css-tlfecz-indicatorContainer svg,
.filter_dropdown_list .css-1okebmr-indicatorSeparator+.css-1gtu0rj-indicatorContainer svg,
.sort_order_block .css-1okebmr-indicatorSeparator+.css-tlfecz-indicatorContainer svg,
.sort_order_block .css-1okebmr-indicatorSeparator+.css-1gtu0rj-indicatorContainer svg {
  display: none;
}

.navbar_right .css-b62m3t-container {
  margin-right: 18px;
}

.navbar_right .css-1s2u09g-control,
.navbar_right .css-1pahdxg-control,
.profile_detail_content .css-1s2u09g-control,
.profile_detail_content .css-1pahdxg-control {
  /* max-width: 109px; */
  min-height: 25px;
  border-color: var(--bs-gray-200);
  box-shadow: inset -2px -2px 0 #E0E0E0;
}

.navbar_right .css-1s2u09g-control .css-319lph-ValueContainer,
.navbar_right .css-1pahdxg-control .css-319lph-ValueContainer {
  padding: 2px 8px;
}

.navbar_right .css-1s2u09g-control .css-6j8wv5-Input,
.navbar_right .css-1pahdxg-control .css-6j8wv5-Input {
  margin: 0;
  padding: 0;
}

.navbar_right .css-319lph-ValueContainer .css-qc6sy-singleValue,
.profile_detail_content .css-319lph-ValueContainer .css-qc6sy-singleValue {
  font-size: 13px;
  text-transform: uppercase;
  color: var(--bs-dark);
}

.navbar_right .css-tlfecz-indicatorContainer,
.navbar_right .css-1gtu0rj-indicatorContainer,
.profile_detail_content .css-tlfecz-indicatorContainer,
.profile_detail_content .css-1gtu0rj-indicatorContainer {
  color: rgba(1, 41, 58, 0.45);
  padding: 0 15px 0 0;
}

.manually_points_modal .css-1s2u09g-control {
  border-color: var(--bs-light);
  box-shadow: inset -4px -4px var(--bs-light);
}

/* !!! results_sort_order_block !!! */
.results_sort_order_block {
  margin-bottom: 15px;
}

.all-collapse {
  text-align: right;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 15px;
  cursor: pointer;
  font-weight: 600;
}

.results_block h4 {
  font-size: 34px;
  line-height: 40px;
  color: var(--bs-dark);
  margin: 0 28px 0 0;
}

.results_block .form-check-inline {
  margin-bottom: 0;
}

.sort_order_block>span {
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  margin-right: 10px;
}

@media (min-width: 576px) {
  .filter_dropdown_list li {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .filter_dropdown_list li {
    width: 33.33%;
  }
}

@media (max-width: 575px) {
  .results_block {
    flex-wrap: wrap;
    row-gap: 10px;
    margin-bottom: 10px;
  }

  .results_block h4,
  .results_block h5 {
    width: 100%;
    margin: 0;
  }

  /* .program_cta {
    flex-direction: column;
  } */
  /* .program_card .btn-link {
    margin: 0 0 15px 0;
  } */
  .program_right_cart_head h2 {
    max-width: 100%;
  }

  .program_right_cart_head .program_price {
    margin-top: 10px;
  }

  .wishlist_and_short_sms {
    margin-top: 10px;
  }
}

/* !!!!!! CPD Program Calendar View !!!!! */
.rbc-calendar .rbc-toolbar {
  margin-bottom: 30px;
}

td.rbc-agenda-event-cell:hover {
  cursor: pointer;
}

.rbc-toolbar .rbc-toolbar-label {
  font-size: 34px;
  line-height: 40px;
  font-weight: 400;
  color: var(--bs-dark);
  margin: 0 28px 0 0;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group button {
  box-shadow: inset -2px -2px 0 rgb(0 78 109 / 15%);
  border-color: var(--bs-primary);
  border-radius: 3px;
  color: var(--bs-primary);
  transition: all 0.3s ease-in-out;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group button:hover,
.rbc-calendar .rbc-toolbar .rbc-btn-group button.rbc-active {
  background: var(--bs-primary);
  color: var(--bs-white);
  box-shadow: inset -2px -2px 0 var(--bs-dark);
}

.rbc-calendar .rbc-toolbar .rbc-btn-group {
  column-gap: 5px;
  display: flex;
  flex-wrap: wrap;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group button+button {
  margin-left: 0;
}

/**/
.rbc-month-view {
  border-color: rgba(var(--bs-primary-rgb), 0.1);
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
}

.rbc-header {
  background: var(--bs-dark);
  padding: 15px;
  border-color: var(--bs-dark);
  color: var(--bs-white);
}

.rbc-header+.rbc-header {
  border-color: var(--bs-light);
}

.rbc-day-bg+.rbc-day-bg,
.rbc-month-row+.rbc-month-row {
  border-color: rgba(var(--bs-primary-rgb), 0.1);
}

.rbc-calendar .rbc-off-range-bg {
  background: var(--bs-gray-200);
}

.rbc-calendar .rbc-today {
  background-color: var(--bs-light);
}

.rbc-button-link {
  color: var(--bs-dark);
}

.rbc-off-range .rbc-button-link {
  color: var(--bs-gray-500);
}

.rbc-calendar .rbc-event,
.rbc-calendar .rbc-day-slot .rbc-background-event {
  background-color: var(--bs-primary);
  font-size: 14px;
  border-radius: 3px;
}
.rbc-calendar .rbc-event.pgone {
  background: #adb5bd;
  pointer-events: none;
}
.rbc-calendar .rbc-event:hover,
.rbc-calendar .rbc-day-slot .rbc-background-event:hover {
  background-color: var(--bs-secondary);
  color: var(--bs-dark);
}


@media (max-width: 575px) {
  .filter_list_wrapper {
    height: 230px;
    margin-top: -40px;
  }

  .filter_list .form-check-label {
    padding: 6px 13px 4px;
    white-space: nowrap;
    font-size: 14px;
  }

  .filter_list_wrapper .filter_title {
    width: 100%;
  }
}

.banner-icon svg {
  margin: 10px 0;
  max-width: 45px;
  fill: #fff;
}



@media print {
  .top_bar_wrapper,
  .navbar_right,
  .navbar-collapse,
  .wishlist_link,
  .print_info,
  .form_check_group,
  .share_colleagues_link,
  .cta_wrapper,
  .can_we_help_button,
  .footer_top,
  .footer_middle,
  .footer_bottom,
  .chat-widget-minimized,
  .quantity_block,
  .cart_link,
  .CookieConsent
  {
    display: none!important;
  }
  .accordion-collapse{
    display: block!important;
  }
}